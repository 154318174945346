import {
    Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, FormControlLabel, Checkbox, TextField, IconButton, Modal, Grid,
    CircularProgress,
    Divider
} from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../../Context/Context';
import apiUrl from '../../../Config';
import { CloseOutlined } from '@mui/icons-material';
import SuccessPopup from '../../../Common/SuccessPopup';
import { Pencil } from 'lucide-react';


const DisplayAddons = () => {
    const { state } = useLocation();
    const { token, successPopup, succesMessage, setSuccessPopup, closePopup, setSuccesMessage } = useContext(Context);
    const subServiceId = state.subServiceId;

    const [openModal, setOpenModal] = useState(false);
    const [addOnList, setAddOnList] = useState([]);
    const [formData, setFormData] = useState({
        Id: 0,
        ServiceId: subServiceId || '',
        Name: '',
        Description: '',
        BasePrice: '',
        ExpectedHours: '',
        ServiceClassificationType: 'MicroService',
        SequenceNumber: '',
        ImageFile: '',
        ImagePreview: '',
        IsActive: false,
        IsDeleted: false,
    });
    const [loading, setLoading] = useState(false)

    const fetchAddOns = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`https://api.sevaki.in/servicetypes/getaddonservices?subServiceId=${subServiceId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            setAddOnList(response.data);
        } catch (error) {
            console.error('Error fetching add-ons:', error);
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchAddOns();
    }, [subServiceId]);

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: ['BasePrice', 'ExpectedHours', 'Id', 'SubServiceId'].includes(name) ? Number(value) : value,
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const handleImageChange = (event) => {
        const { files } = event.target;
        if (files && files.length > 0) {
            setFormData((prevData) => ({
                ...prevData,
                ImageFile: files[0],
                ImagePreview: URL.createObjectURL(files[0]),
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('Id', formData.Id);
            formDataToSend.append('ServiceId', formData.ServiceId);
            formDataToSend.append('Name', formData.Name);
            formDataToSend.append('Description', formData.Description);
            formDataToSend.append('BasePrice', formData.BasePrice);
            formDataToSend.append('ExpectedHours', formData.ExpectedHours);
            formDataToSend.append('IsActive', formData.IsActive);
            formDataToSend.append('IsDeleted', formData.IsDeleted);
            formDataToSend.append('ImageFile.FileType', 'Image');
            formDataToSend.append('ImageFile.FileDetails', formData.ImageFile);

            if (formData.Id) {
                const response = await axios.put(apiUrl + '/servicetypes/updateaddonservicetype', formDataToSend, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                });
                if (response.data.status == true) {
                    setSuccesMessage(response.data.message)
                    setSuccessPopup(true)
                    fetchAddOns()
                    handleCloseModal();
                    resetForm()
                }
            } else {
                const response = await axios.post(apiUrl + '/servicetypes/createaddonservicetype', formDataToSend, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                });

                if (response.data.status == true) {
                    setSuccesMessage(response.data.message)
                    setSuccessPopup(true)
                    fetchAddOns()
                    handleCloseModal();
                    resetForm()
                }
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    const handleEditClick = (addOn) => {
        setFormData({
            Id: addOn.id,
            ServiceId: subServiceId,
            Name: addOn.name,
            Description: addOn.description,
            BasePrice: addOn.basePrice,
            ExpectedHours: addOn.expectedHours,
            // OfferPrice: addOn.offerPrice,
            IsActive: addOn.isActive,
            IsDeleted: addOn.isDeleted,
            ImageFile: addOn.imagePath,
            ImagePreview: addOn.imagePath ? apiUrl + "/" + addOn.imagePath : ''
        });
        setOpenModal(true);
    };

    const resetForm = () => {
        setFormData({
            Id: 0,
            ServiceId: subServiceId || '',
            Name: '',
            Description: '',
            BasePrice: '',
            ExpectedHours: '',
            ServiceClassificationType: 'MicroService',
            SequenceNumber: '',
            ImageFile: '',
            ImagePreview: '',
            // OfferPrice: '',
            IsActive: false,
            IsDeleted: false,
        });
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb:3 }}>
                <Typography variant="h4" color="initial" fontWeight={600} mb={2}>
                    Add On's
                </Typography>
                <Button variant="contained" color="primary" onClick={() => { setOpenModal(true); resetForm(); }}>
                    Create Add On
                </Button>
            </Box>

            <Divider sx={{mb:3}}/>

            {
                loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100px" mt={2}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Base Price</TableCell>
                                    <TableCell>Expected Hours</TableCell>
                                    <TableCell>Is Active</TableCell>
                                    <TableCell>Is Deleted</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {addOnList && addOnList?.length > 0 ? (
                                    addOnList?.map((addOn) => (
                                        <TableRow key={addOn?.id}>
                                            <TableCell>
                                                {addOn?.imagePath ? (
                                                    <img
                                                        src={apiUrl + "/" + addOn?.imagePath}
                                                        alt={addOn?.name}
                                                        style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                                    />
                                                ) : (
                                                    'No Image'
                                                )}
                                            </TableCell>
                                            <TableCell>{addOn?.name}</TableCell>
                                            <TableCell>{addOn?.description}</TableCell>
                                            <TableCell>{addOn?.basePrice}</TableCell>
                                            <TableCell>{addOn?.expectedHours}</TableCell>
                                            <TableCell>{addOn?.isActive ? 'Yes' : 'No'}</TableCell>
                                            <TableCell>{addOn?.isDeleted ? 'Yes' : 'No'}</TableCell>
                                            <TableCell>
                                                <IconButton color="primary">
                                                    <Pencil onClick={() => handleEditClick(addOn)} size={18} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                                            <Typography variant="h6" component="h2" gutterBottom>
                                                No add-ons available.
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>

                        </Table>
                    </TableContainer>
                )
            }

            <Modal open={openModal} onClose={handleCloseModal}>
                <Grid container justifyContent='center' alignItems='center' height='100vh'>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            maxWidth: 600,
                            width: '90%',
                            borderRadius: 2,
                        }}
                    >
                        <Typography variant="h4" fontWeight={600} mb={4}>
                            {formData.Id ? 'Edit' : 'Add'} Add On
                        </Typography>

                        <IconButton
                            onClick={handleCloseModal}
                            sx={{
                                position: 'absolute',
                                top: '20px',
                                right: '20px',
                            }}
                        >
                            <CloseOutlined />
                        </IconButton>

                        <Box component={'form'} onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Name"
                                        name="Name"
                                        size="small"
                                        value={formData.Name}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Description"
                                        name="Description"
                                        size="small"
                                        value={formData.Description}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Base Price"
                                        name="BasePrice"
                                        size="small"
                                        type="number"
                                        value={formData.BasePrice}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Expected Hours"
                                        name="ExpectedHours"
                                        size="small"
                                        type="number"
                                        value={formData.ExpectedHours}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <input
                                        type="file"
                                        name="ImageFile"
                                        accept="image/*"
                                        required={!formData.ImagePreview}
                                        onChange={handleImageChange}
                                    />
                                    {formData.ImagePreview && (
                                        <img
                                            src={formData.ImagePreview}
                                            alt="Preview"
                                            style={{ marginTop: '10px', width: '100px', height: '100px', objectFit: 'cover' }}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.IsActive}
                                                onChange={handleCheckboxChange}
                                                name="IsActive"
                                            />
                                        }
                                        label="Is Active"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.IsDeleted}
                                                onChange={handleCheckboxChange}
                                                name="IsDeleted"
                                            />
                                        }
                                        label="Is Deleted"
                                    />
                                </Grid>
                            </Grid>

                            <Button
                                variant="contained"
                                color="primary"
                                type='submit'
                                fullWidth
                                sx={{ mt: 3 }}
                            >
                                {formData.Id ? 'Update' : 'Add'} Add On
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Modal>
            {succesMessage ?
                <SuccessPopup
                    open={successPopup}
                    onClose={closePopup}
                    message={succesMessage}
                /> : ""}
        </Box>
    );
};

export default DisplayAddons;
