import { Box, Typography, Collapse, IconButton, Button, TextField, FormControl, InputLabel, MenuItem, Select, CircularProgress, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import axios from 'axios';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { Context } from '../../Context/Context';
import apiUrl from '../../Config';
import { Pencil, Trash2 } from 'lucide-react';

import HtmlEditor from '../../Common/HtmlEditor';
import SuccessPopup from '../../Common/SuccessPopup';
import DeleteConfirm from '../../Common/DeleteConfirm';

const Faq = () => {

    const { token, successPopup,
        succesMessage,
        setSuccessPopup,
        closePopup,
        setSuccesMessage,
        isConfirmDeleteModelOpen,
        setIsConfirmDeleteModelOpen,
        itemIdToDelete,
        setItemIdToDelete } = useContext(Context)

    const [faqs, setFaqs] = useState([]);
    const [faqType, setFaqType] = useState("UserApp");
    const [showAddFaqFields, setShowAddFaqFields] = useState(false);
    const [newQuestion, setNewQuestion] = useState('');
    const [newAnswer, setNewAnswer] = useState('');
    const [expandedFaqId, setExpandedFaqId] = useState(null);
    const [editingFaqId, setEditingFaqId] = useState(null);
    const answerRef = useRef(null);
    const [loading, setLoading] = useState(false)

    const [errors, setErrors] = useState()

    const handleChange = (event) => {
        setFaqType(event.target.value);
    };

    const handleAddFaq = () => {
        setShowAddFaqFields(!showAddFaqFields);
        setNewQuestion('');
        setNewAnswer('');
        setEditingFaqId(null);
    };

    const addFaq = async () => {
        const newFaq = {
            id: 0,
            faQ_TypeId: faqType,
            question: newQuestion,
            answer: newAnswer,
            isActive: true
        };

        try {
            const response = await axios.post(apiUrl + '/faqs/create', newFaq, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            if (response?.data?.status === true) {
                setSuccesMessage(response?.data?.message || "Faq created successfull")
                setSuccessPopup(true)

                setNewQuestion('');
                setNewAnswer('');
                setShowAddFaqFields(false);
                fetchFaqData();
            }

        } catch (error) {
            if (error?.response?.data?.status === 401) {
                setSuccesMessage(error?.response?.data?.title)
                setSuccessPopup(true)
            } else {
                setErrors(error?.response?.data?.message || "Something went wrong");
            }
        }
    };

    const fetchFaqData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(apiUrl + `/faqs/getfaqs?fAQType=${faqType}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            if (response.data.status === true) {
                setFaqs(response.data.faqs);
            }

        } catch (error) {
            if (error?.response?.data?.status === 401) {
                setSuccesMessage(error?.response?.data?.title)
                setSuccessPopup(true)
            } else {
                setErrors(error?.response?.data?.message || "Something went wrong");
            }
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchFaqData();
    }, [faqType]);

    const toggleExpand = (id) => {
        setExpandedFaqId(expandedFaqId === id ? null : id);
    };

    const handleEdit = (faq) => {
        setEditingFaqId(faq?.id);
        setNewQuestion(faq?.question);
        setNewAnswer(faq?.answer);
        setShowAddFaqFields(true);

        setTimeout(() => {
            if (answerRef.current) {
                answerRef.current.innerHTML = faq.answer;
            }
        }, 0);
    };

    const editFaq = async () => {
        const updatedFaq = {
            id: editingFaqId,
            faQ_TypeId: faqType,
            question: newQuestion,
            answer: newAnswer,
            isActive: true
        };

        try {
            const response = await axios.post(apiUrl + '/faqs/update', updatedFaq, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            if (response?.data?.status === true) {
                setSuccesMessage(response?.data?.message || "Faq updated successfull")
                setSuccessPopup(true)
            }
            setNewQuestion('');
            setNewAnswer('');
            setEditingFaqId(null);
            setShowAddFaqFields(false);
            fetchFaqData();
        } catch (error) {
            if (error?.response?.data?.status === 401) {
                setSuccesMessage(error?.response?.data?.title)
                setSuccessPopup(true)
            } else {
                setErrors(error?.response?.data?.message || "Something went wrong");
            }
        }
    };

    const handleDeleteFaq = (faqId) => {
        setIsConfirmDeleteModelOpen(true)
        setItemIdToDelete(faqId)
    }

    const deleteFaq = async () => {
        try {
            const response = await axios.delete(apiUrl + `/faqs/${itemIdToDelete}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            if (response?.data?.status === true) {
                setSuccesMessage(response?.data?.message || "Faq deleted successfull")
                setSuccessPopup(true)
                fetchFaqData();
            }

        } catch (error) {
            if (error?.response?.data?.status === 401) {
                setSuccesMessage(error?.response?.data?.title)
                setSuccessPopup(true)
            } else {
                setErrors(error?.response?.data?.message || "Something went wrong");
            }
        }
    };

    return (
        <Box mt={8}>
            <Typography variant="h4" fontWeight={600} textAlign={'center'}>
                Frequently Asked Questions
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', margin: '25px 0' }}>
                <FormControl sx={{ width: '150px' }}>
                    <InputLabel id="faq-category">Faq Category</InputLabel>
                    <Select
                        labelId="faq-category"
                        value={faqType}
                        label="Faq Category"
                        size='small'
                        onChange={handleChange}
                    >
                        <MenuItem value={'UserApp'}>User App</MenuItem>
                        <MenuItem value={'WorkerApp'}>Worker App</MenuItem>
                        <MenuItem value={'WebApp'}>Web App</MenuItem>
                    </Select>
                </FormControl>
                <Button variant="contained" sx={{ backgroundColor: '#7E60BF' }} onClick={handleAddFaq}>
                    {showAddFaqFields ? 'Cancel' : 'Add Faq'}
                </Button>
            </Box>

            <Divider sx={{  mb: 3, mt: 3 }} />

            {showAddFaqFields && (
                <Box sx={{ marginBottom: 2 }} component={'form'} onSubmit={editingFaqId ? editFaq : addFaq}>
                    <TextField
                        label="Question"
                        variant="outlined"
                        size='small'
                        fullWidth
                        required
                        value={newQuestion}
                        onChange={(e) => setNewQuestion(e.target.value)}
                        sx={{ marginBottom: 1 }}
                    />
                    <Typography variant="h6" color="initial" mt={2} >Description</Typography>
                    <HtmlEditor
                        value={newAnswer}
                        onChange={(newContent) => setNewAnswer(newContent)}
                        placeholder="Enter your description here..."
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>

                        <Button variant="contained" sx={{ backgroundColor: '#7E60BF', marginTop: 1 }} type='submit' >
                            {editingFaqId ? 'Update Faq' : 'Submit Faq'}
                        </Button>
                    </Box>
                </Box>
            )}
            {
                loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100px" mt={2}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {faqs?.length > 0 ? (
                            faqs?.map((faq) => (
                                <Box key={faq?.id} mt={2} sx={{ border: "1px solid #EAECF0", padding: "6px 11px", borderRadius: "8px" }}>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>{faq?.question}</Typography>
                                        <Box>
                                            <IconButton onClick={() => toggleExpand(faq?.id)} sx={{ marginRight: '15px' }}>
                                                {expandedFaqId === faq?.id ?
                                                    <RemoveIcon fontSize='small' sx={{ color: "primary.main", borderRadius: "50%" }} /> :
                                                    <AddIcon fontSize='small' sx={{ color: "primary.main", borderRadius: "50%" }} />}
                                            </IconButton>
                                            <IconButton onClick={() => handleEdit(faq)} sx={{ marginRight: '15px' }} color='primary'>
                                                <Pencil size={18} />
                                            </IconButton>
                                            <IconButton onClick={() => handleDeleteFaq(faq?.id)} color="error">
                                                <Trash2 size={18} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Collapse in={expandedFaqId === faq?.id}>
                                        <Box mt={1}>
                                            <Typography variant='h6' sx={{ color: "#667085" }} dangerouslySetInnerHTML={{ __html: faq?.answer }} />
                                        </Box>
                                    </Collapse>
                                </Box>
                            ))
                        ) : (
                            <Typography textAlign={'center'}>No FAQs available.</Typography>
                        )}
                    </>
                )
            }

            <DeleteConfirm
                open={isConfirmDeleteModelOpen}
                onClose={() => setIsConfirmDeleteModelOpen(false)}
                onConfirm={deleteFaq}

            />

            {succesMessage ?
                <SuccessPopup
                    open={successPopup}
                    onClose={closePopup}
                    message={succesMessage}
                /> : ""}
        </Box>
    );
};

export default Faq;

