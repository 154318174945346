import { Box, Button, TextField, Typography, Grid, Modal } from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import apiUrl from "../Config";
import { Context } from "../Context/Context";
import SuccessPopup from "../Common/SuccessPopup";

const ChangePassword = ({ open, onClose }) => {

  const { successPopup, token,
    succesMessage,
    setSuccessPopup,
    closePopup,
    setSuccesMessage } = useContext(Context)
  const [errors, setErrors] = useState({});
  const [userData, setUserData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData({
      ...userData,
      [name]: value,
    });
    setErrors("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();


    try {
      const response = await axios.post(
        apiUrl + "/users/changepassword",
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === true) {

        setSuccesMessage(response.data.message || "Change password successfully")
        setSuccessPopup(true)
        onClose();
      } else if (response.data.status === false) {
        setErrors(response.data.message);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setSuccesMessage(error?.response?.data?.title)
        setSuccessPopup(true)
      } else
        if (error.response && error.response.data.errors) {
          setErrors(error.response.data.errors);
        } else {
          setErrors({ general: ["An unexpected error occurred."] });
        }
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-change-password"
        aria-describedby="modal-change-password-description"
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100vh" }}
        >
          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
                width: "400px",
              }}
            >
              <Typography variant="h4" textAlign="center" mb={2}>
                Change Password
              </Typography>
              <Box component="form" onSubmit={handleSubmit} sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  size="small"
                  fullWidth
                  label="Old Password"
                  name="oldPassword"
                  type="password"
                  variant="outlined"
                  value={userData.oldPassword}
                  onChange={handleChange}
                  required
                  sx={{ mb: 2, "& .MuiOutlinedInput-root": { "&:hover fieldset": { borderColor: "#E4E4E4" } } }}
                />
                <TextField
                  size="small"
                  fullWidth
                  label="New Password"
                  name="newPassword"
                  type="password"
                  variant="outlined"
                  value={userData.newPassword}
                  onChange={handleChange}
                  required
                  sx={{ mb: 2, "& .MuiOutlinedInput-root": { "&:hover fieldset": { borderColor: "#E4E4E4" } } }}
                />
                <TextField
                  fullWidth
                  size="small"
                  type="password"
                  label="Confirm Password"
                  name="confirmPassword"
                  value={userData.confirmPassword}
                  onChange={handleChange}
                  required
                  sx={{ mb: 2, "& .MuiOutlinedInput-root": { "&:hover fieldset": { borderColor: "#E4E4E4" } } }}
                />

                {errors.description && (
                  <Typography color="error" textAlign="center" mb={2}>
                    {errors.description}
                  </Typography>
                )}
                {errors.ConfirmPassword && (
                  <Typography color="error" textAlign="center" mb={2}>
                    {errors.ConfirmPassword}
                  </Typography>
                )}
                {errors.general && (
                  <Typography color="error" textAlign="center" mb={2}>
                    {errors.general}
                  </Typography>
                )}

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ padding: "6px 30px" }}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ padding: "6px 30px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Modal>
      {succesMessage ?
        <SuccessPopup
          open={successPopup}
          onClose={closePopup}
          message={succesMessage}
        /> : ""}
    </>
  );
};

export default ChangePassword;
