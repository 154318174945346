import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Select,
    MenuItem,
    Modal,
    TextField,
    Grid,
    Checkbox,
    FormControlLabel,
    IconButton
} from '@mui/material';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Context } from '../../../Context/Context';
import apiUrl from '../../../Config';
import { CloseOutlined } from '@mui/icons-material';
import SuccessPopup from '../../../Common/SuccessPopup';
import { Pencil, Trash2 } from 'lucide-react';
import DeleteConfirm from '../../../Common/DeleteConfirm';


const ServiceSteps = () => {

    const { token, successPopup, succesMessage, setSuccessPopup, closePopup, setSuccesMessage, isConfirmDeleteModelOpen,
        setIsConfirmDeleteModelOpen,
        itemIdToDelete,
        setItemIdToDelete } = useContext(Context)
    const { state } = useLocation();
    const { miniServiceId } = state || 0;
    const [steps, setSteps] = useState([]);
    const [serviceType, setServiceType] = useState("MiniService");
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [previewImage, setPreviewImage] = useState();
    const [stepData, setStepData] = useState({
        Id: 0,
        ServiceId: miniServiceId || 0,
        Name: '',
        Description: '',
        ServiceClassificationTypes: '',
        SequenceNumber: '',
        ImageFile: {
            Id: 0,
            FileDetails: null,
            FileType: '',
            IsDeleted: false,
        }
    });

    const fetchSteps = async () => {
        try {
            const response = await axios.get(
                apiUrl + `/servicetypes/getservicedetailsteps?id=${miniServiceId}&serviceTypes=${serviceType}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }
            );
            setSteps(response.data);
        } catch (error) {
            console.error("Error fetching steps:", error);
        }
    };

    useEffect(() => {
        fetchSteps();
    }, [serviceType]);

    const handleServiceTypeChange = (event) => {
        setServiceType(event.target.value);
    };

    const handleFieldChange = (event) => {
        const { name, value } = event.target;
        setStepData({ ...stepData, [name]: value });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setStepData((prevData) => ({
            ...prevData,
            ImageFile: {
                ...prevData.ImageFile,
                FileDetails: file,
            },
        }));

        const reader = new FileReader();
        reader.onload = () => setPreviewImage(reader.result);
        reader.readAsDataURL(file);
    };

    const handleEdit = (step) => {
        setStepData({
            Id: step.id,
            ServiceId: step.serviceId,
            Name: step.title,
            Description: step.description,
            ServiceClassificationTypes: step.serviceClassificationTypes,
            SequenceNumber: step.sequenceNumber,
        });

        setPreviewImage(apiUrl + "/" + step.imagePath || null);
        setIsEdit(true);
        setOpen(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formDataToSend = new FormData();

        formDataToSend.append('Id', stepData.Id);
        formDataToSend.append('ServiceId', stepData.ServiceId);
        formDataToSend.append('Name', stepData.Name);
        formDataToSend.append('Description', stepData.Description);
        formDataToSend.append('ServiceClassificationTypes', stepData.ServiceClassificationTypes);
        formDataToSend.append('SequenceNumber', stepData.SequenceNumber);

        if (stepData?.ImageFile?.FileDetails) {
            formDataToSend.append('ImageFile.FileDetails', stepData?.ImageFile?.FileDetails);
        }
        formDataToSend.append('ImageFile.FileType', "Image");

        try {
            const api = isEdit
                ? apiUrl + '/servicetypes/updateservicedetailstep'
                : apiUrl + '/servicetypes/createservicedetailstep';

            const method = isEdit ? 'put' : 'post';
            const response = await axios[method](api, formDataToSend, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });

            if (response?.data?.status === true) {
                setSuccesMessage("Service field created successfully");
                setSuccessPopup(true)
                setOpen(false);
                fetchSteps();
                resetForm();
            }
        } catch (error) {
            console.error("Error processing step:", error);
        }
    };

    const handleZoneDelete = (stepId) => {
        setIsConfirmDeleteModelOpen(true)
        setItemIdToDelete(stepId)
    }

    const handleDelete = async () => {
        setIsConfirmDeleteModelOpen(false)
        const response = await axios.get(apiUrl + `/servicetypes/deleteservicedetailstep?id=${itemIdToDelete}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        if (response?.data?.status === true) {
            setSuccesMessage(response.data.message);
            setSuccessPopup(true)
            fetchSteps();
        }
    }

    const resetForm = () => {
        setStepData({
            Id: 0,
            ServiceId: miniServiceId || 0,
            Name: '',
            Description: '',
            ServiceClassificationTypes: '',
            SequenceNumber: '',
            ImageFile: {
                Id: 0,
                FileDetails: null,
                FileType: '',
                IsDeleted: false,
            },
        });
        setPreviewImage(null);
        setIsEdit(false);
    };

    return (
        <Box mt={8}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h4">Service Steps</Typography>
                <Box>
                    <Typography variant="subtitle1">Select Service Type:</Typography>
                    <Select
                        value={serviceType}
                        size="small"
                        onChange={handleServiceTypeChange}
                        fullWidth
                        style={{ marginTop: "8px" }}
                    >
                        <MenuItem value="Service">Service</MenuItem>
                        <MenuItem value="SubService">SubService</MenuItem>
                        <MenuItem value="MiniService">MiniService</MenuItem>
                        <MenuItem value="MicroService">MicroService</MenuItem>
                    </Select>
                </Box>
                <Button variant="contained" onClick={() => { setOpen(true); resetForm(); }}>
                    Add New Step
                </Button>

            </Box>
            <Box mt={3} sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', }}>
                {steps?.length > 0 ? (
                    steps?.map((step, index) => (
                        <Box mb={2} key={index} sx={{ border: '2px solid lightgrey', width: '350px', padding: '15px', borderRadius: '10px' }}>
                            <Box>
                                <Typography variant="h6" color="initial">Step : {step?.sequenceNumber}</Typography>
                                {step?.imagePath ? (
                                    <img
                                        src={apiUrl + "/" + step?.imagePath}
                                        alt={step?.title}
                                        style={{ width: '200px', height: '100px', objectFit: 'cover' }}
                                    />
                                ) : (
                                    'No Image'
                                )}
                            </Box>
                            <Typography variant="h6">{step?.title}</Typography>
                            <Typography>{step?.description}</Typography>
                            <Button onClick={() => handleEdit(step)} startIcon={<Pencil size={'18px'} />}> </Button>
                            <Button onClick={() => handleZoneDelete(step?.id)} startIcon={<Trash2 size={'18px'} color='red' />}></Button>
                        </Box>
                    ))
                ) : (
                    <Typography>No steps available.</Typography>
                )}
            </Box>
            <Modal open={open} onClose={() => setOpen(false)}>
                <Grid container justifyContent='center' alignItems='center' height='100vh'>
                    <Box component={'form'} onSubmit={handleSubmit}
                        sx={{
                            position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper', boxShadow: 24, p: 4, maxWidth: 600, width: '90%', borderRadius: 2,
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h5" mb={2}>
                                {isEdit ? "Edit Step" : "Add New Step"}
                            </Typography>
                            <IconButton
                                onClick={() => setOpen(false)}
                                sx={{
                                    position: 'absolute',
                                    top: '20px',
                                    right: '20px',
                                }}
                            >
                                <CloseOutlined />
                            </IconButton>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    name="Name"
                                    required
                                    size="small"
                                    value={stepData.Name}
                                    onChange={handleFieldChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Description"
                                    name="Description"
                                    required
                                    size="small"
                                    value={stepData.Description}
                                    onChange={handleFieldChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Select
                                    fullWidth
                                    value={stepData.ServiceClassificationTypes || ""}
                                    name="ServiceClassificationTypes"
                                    onChange={handleFieldChange}
                                    displayEmpty
                                    size="small"
                                    required
                                >
                                    <MenuItem value="" disabled>
                                        Select Classification Type
                                    </MenuItem>
                                    <MenuItem value={1}>Service</MenuItem>
                                    <MenuItem value={2}>SubService</MenuItem>
                                    <MenuItem value={3}>MiniService</MenuItem>
                                    <MenuItem value={4}>MicroService</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Sequence Number"
                                    name="SequenceNumber"
                                    required
                                    size="small"
                                    value={stepData.SequenceNumber}
                                    onChange={handleFieldChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <input type="file" accept="image/*" onChange={handleFileChange} required={!previewImage} />
                                {previewImage && <img src={previewImage} width="100px" style={{ marginTop: '10px' }} />}
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" type='submit'>
                                    {isEdit ? "Update Step" : "Add Step"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Modal>

            <DeleteConfirm
                open={isConfirmDeleteModelOpen}
                onClose={() => setIsConfirmDeleteModelOpen(false)}
                onConfirm={handleDelete}
            />

            {succesMessage ?
                <SuccessPopup
                    open={successPopup}
                    onClose={closePopup}
                    message={succesMessage}
                /> : ""}
        </Box>
    );
};

export default ServiceSteps;