
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Divider,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
  Modal,
  Grid,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import axios from 'axios';
import { Context } from '../../Context/Context';
import apiUrl from '../../Config';
import SuccessPopup from '../../Common/SuccessPopup';
import { Pencil, Trash2 } from 'lucide-react';
import DeleteConfirm from '../../Common/DeleteConfirm';

const Zone = () => {
  const { token, successPopup,
    succesMessage,
    setSuccessPopup,
    closePopup,
    setSuccesMessage,
    isConfirmDeleteModelOpen,
    setIsConfirmDeleteModelOpen,
    itemIdToDelete,
    setItemIdToDelete } = useContext(Context)
  const [zonesData, setZonesData] = useState([]);
  const [zoneDetails, setZoneDetails] = useState({
    id: 0,
    name: '',
    description: '',
    zoneID: 0,
    isActive: true,
    isDeleted: false,
    zonePincodes: [{ id: 0, zoneID: 0, pincode: '' }],
  });
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState()

  const fetchZones = async () => {
    setLoading(true);
    try {
      const response = await axios.get(apiUrl + '/zone/listzones', {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      if (response?.data?.status === true) {
        setZonesData(response?.data?.zone);
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors || "Error in fetching zones")
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (isEditMode) {
        const response = await axios.post(apiUrl + `/zone/updatezone`, zoneDetails, {
          headers: {
            Authorization: `Bearer ${token}`
          },
        });
        if (response?.data?.status === true) {
          setSuccesMessage(response?.data?.message)
          setSuccessPopup(true)
        }
      } else {
        const response = await axios.post(apiUrl + '/zone/createzone', zoneDetails, {
          headers: {
            Authorization: `Bearer ${token}`
          },
        });
        if (response?.data?.status === true) {
          setSuccesMessage(response?.data?.message)
          setSuccessPopup(true)
        }
      }

      fetchZones();
      setShowModal(false);
      setIsEditMode(false);
      resetForm();
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setSuccesMessage(error?.response?.data?.title)
        setSuccessPopup(true)
      } else {
        setErrors(error?.response?.data?.message || "Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setZoneDetails({
      id: 0,
      name: '',
      description: '',
      zoneID: 0,
      isActive: true,
      isDeleted: false,
      zonePincodes: [{ id: 0, zoneID: 0, pincode: '' }],
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'pincode') {
      setZoneDetails((prev) => ({
        ...prev,
        zonePincodes: prev.zonePincodes.map((p, i) =>
          i === 0 ? { ...p, pincode: value } : p
        ),
      }));
    } else {
      setZoneDetails((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAddPincode = () => {
    setZoneDetails((prev) => ({
      ...prev,
      zonePincodes: [...prev.zonePincodes, { id: 0, zoneID: prev.zoneID, pincode: '' }],
    }));
  };

  const handlePincodeChange = (index, value) => {
    setZoneDetails((prev) => ({
      ...prev,
      zonePincodes: prev.zonePincodes.map((p, i) =>
        i === index ? { ...p, pincode: value } : p
      ),
    }));
  };

  const handleZoneChange = (index, value) => {
    setZoneDetails((prev) => ({
      ...prev,
      zonePincodes: prev.zonePincodes.map((p, i) =>
        i === index ? { ...p, zoneID: value } : p
      )
    }))
  }

  const handleRemovePincode = (index) => {
    setZoneDetails((prev) => ({
      ...prev,
      zonePincodes: prev.zonePincodes.filter((_, i) => i !== index),
    }));
  };

  const handleZoneDelete = (zoneId) => {
    setIsConfirmDeleteModelOpen(true)
    setItemIdToDelete(zoneId)
  }

  const handleEdit = (zone) => {
    setZoneDetails(zone);
    setShowModal(true);
    setIsEditMode(true);
  };

  const handleDelete = async () => {
    setLoading(true);
    setIsConfirmDeleteModelOpen(false)
    try {
      const response = await axios.delete(apiUrl + `/zone/${itemIdToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      if (response.data.status === true) {
        setSuccesMessage(response.data.message || "Zone Deleted successfully")
        setSuccessPopup(true)
        fetchZones();
      }

    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setSuccesMessage(error?.response?.data?.title)
        setSuccessPopup(true)
      } else {
        setErrors(error?.response?.data?.message || "Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchZones();
  }, []);

  const filteredZones = zonesData.filter((zone) =>
    zone.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    zone.zonePincodes.some((pincode) =>
      String(pincode.pincode || '').includes(searchTerm)
    )
  );

  return (
    <Box mt={8}>


      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" color="initial" fontWeight={500}>
          Zones List
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            label="Search by Name or Pincode"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            size="small"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setShowModal(true);
              setIsEditMode(false);
              resetForm();
            }}
          >
            Add Zone
          </Button>
        </Box>
      </Box>


      <Divider sx={{ mb: 3, mt: 3 }} />

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '20vh', }} >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '100px'  }}>Name</TableCell>
                <TableCell sx={{ width: '100px' }}>Description</TableCell>
                <TableCell sx={{ width: '100px' }}>Zone ID</TableCell>
                <TableCell>Pincodes</TableCell>
                <TableCell sx={{ width: '100px', textAlign:'right' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredZones?.map((zone) => (
                <TableRow key={zone?.id}>
                  <TableCell>{zone?.name}</TableCell>
                  <TableCell>{zone?.description}</TableCell>
                  <TableCell>{zone?.zonePincodes[0]?.zoneID}</TableCell>
                  <TableCell>
                    {zone?.zonePincodes?.map((p) => p.pincode).join(', ')}
                  </TableCell>
                  <TableCell align='right'>
                    <IconButton color="primary" onClick={() => handleEdit(zone)}>
                      <Pencil size={18} />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleZoneDelete(zone.id)}>
                      <Trash2 size={18} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}



      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Grid container justifyContent='center' alignItems='center' height='100vh'>
          <Box component='form' onSubmit={handleSubmit}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: '5px',
                overflowY: 'auto',
                maxHeight: '80vh',
                minHeight: '350px',
                // '&::-webkit-scrollbar': {
                //   display: 'none', // Hides the scrollbar in Webkit-based browsers
                // },
                // '-ms-overflow-style': 'none',
                // 'scrollbar-width': 'none'
              }}
            >
              <Typography id="modal-title" variant="h6" component="h2" mb={2}>
                {isEditMode ? 'Edit Zone' : 'Add Zone'}
              </Typography>
              <TextField
                label="Zone Name"
                name="name"
                value={zoneDetails.name}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                size='small'
              />
              <TextField
                label="Description"
                name="description"
                value={zoneDetails.description}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
                size='small'
              />

              {zoneDetails?.zonePincodes?.map((pincode, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <TextField
                    label={`Pincode ${index + 1}`}
                    value={pincode?.pincode}
                    onChange={(e) => handlePincodeChange(index, e.target.value)}
                    fullWidth
                    margin="normal"
                    type="number"
                    required
                    size='small'
                  />
                  <TextField
                    label="Zone ID"
                    name="zoneID"
                    value={pincode?.zoneID}
                    onChange={(e) => handleZoneChange(index, e.target.value)}
                    fullWidth
                    size='small'
                    margin="normal"
                    type="number"
                    required
                  />
                  {index > 0 && (
                    <IconButton color="error" onClick={() => handleRemovePincode(index)}>
                      <Delete />
                    </IconButton>
                  )}
                </Box>
              ))}
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, gap: 2 }}>
                <Button onClick={handleAddPincode} variant="outlined" color="primary">
                  Add Pincode
                </Button>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
                <Button onClick={() => setShowModal(false)} variant="contained" color='error'>
                  Cancel
                </Button>
                <Button type='submit' variant="contained" color="primary">
                  {isEditMode ? 'Update' : 'Submit'}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Modal>

      <DeleteConfirm
        open={isConfirmDeleteModelOpen}
        onClose={() => setIsConfirmDeleteModelOpen(false)}
        onConfirm={handleDelete}

      />

      {succesMessage ?
        <SuccessPopup
          open={successPopup}
          onClose={closePopup}
          message={succesMessage}
        /> : ""}

    </Box>
  );
};

export default Zone;



