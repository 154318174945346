
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { ArrowLeft } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import apiUrl from "../../Config";
import { Context } from "../../Context/Context";
import SuccessPopup from '../../Common/SuccessPopup';

const AddRoles = () => {
  const { token, successPopup,
    succesMessage,
    setSuccessPopup,
    closePopup,
    setSuccesMessage, } = useContext(Context);
  const location = useLocation();
  const id = location?.state?.id;
  const [claims, setClaims] = useState([]);
  const [selectedClaims, setSelectedClaims] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [modules, setModules] = useState([]);
  const [roles, setRoles] = useState({
    name: "",
    description: "",
    isActive: false,
    isDeleted: false,
  });
  const [claimsGroup, setClaimsGroup] = useState({});
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setRoles({
      ...roles,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const getRoleClaims = async () => {
    try {
      const response = await axios.get(apiUrl + `/rolemanager/getallpolicies`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.status === true) {
        setClaims(response?.data?.policies);
      }

    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setSuccesMessage(error?.response?.data?.title)
        setSuccessPopup(true)
      } else {
        setErrorMessage(error?.response?.data?.message || "Something went wrong");
      }
    }
  };

  const getModules = async () => {
    try {
      const response = await axios.get(apiUrl + `/rolemanager/listpolicymodules`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.status === true) {
        setModules(response?.data?.modules || []);

      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setSuccesMessage(error?.response?.data?.title)
        setSuccessPopup(true)
      } else {
        setErrorMessage(error?.response?.data?.message || "Something went wrong");
      }
    }
  };

  const groupClaimsByModule = (claims) => {
    return claims.reduce((grouped, claim) => {
      const moduleId = claim?.moduleId;
      if (!grouped[moduleId]) {
        grouped[moduleId] = [];
      }
      grouped[moduleId].push(claim);
      return grouped;
    }, {});
  };

  useEffect(() => {

    getRoleClaims();
    getModules();
  }, []);

  useEffect(() => {
    if (claims?.length > 0) {
      const groupedClaims = groupClaimsByModule(claims);
      setClaimsGroup(groupedClaims);
    }
  }, [claims]);

  const isEditing = location?.state && location?.state;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    try {
      if (isEditing && id) {
        try {
          const response = await axios.put(
            `${apiUrl}/rolemanager/${id}?roleName=${roles.name}&description=${roles.description}&isActive=${roles.isActive}`,
            selectedClaims,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response?.data?.status === true) {
            setSuccesMessage("Role update successfull");
            setSuccessPopup(true)
          }
          navigate(-1);
        } catch (error) {
          if (error?.response && error?.response?.data?.errors) {
            const { description, roleName } = error?.response?.data?.errors;
            setErrorMessage(roleName || description || "An error occurred");
          } else {
            if (error?.response?.data?.status === 401) {
              setSuccesMessage(error?.response?.data?.title)
              setSuccessPopup(true)
            } else {
              setErrorMessage(error?.response?.data?.message || "Something went wrong");
            }
          }
        }
      } else {
        try {
          const response = await axios.post(
            `${apiUrl}/rolemanager/createrole`,
            roles,
            {
              headers: {
                accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response?.data?.status === true) {
            setSuccesMessage("Role is created successfull");
            setSuccessPopup(true)

          }
          navigate(-1);

        } catch (error) {
          if (error?.response && error?.response?.data?.errors) {
            const { description, roleName } = error?.response?.data?.errors;
            setErrorMessage(roleName || description || "An error occurred");
          } else {
            if (error?.response?.data?.status === 401 || error?.response?.data?.status === 403) {
              setSuccesMessage(error?.response?.data?.title)
              setSuccessPopup(true)
            } else {
              setErrorMessage(error?.response?.data?.message || "Something went wrong");
            }
          }
        }
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setSuccesMessage(error?.response?.data?.title)
        setSuccessPopup(true)
      } else {
        setErrorMessage(error?.response?.data?.message || "Something went wrong");
      }

    }
  };

  const handleClaimChange = (claimName) => {
    setSelectedClaims((prevSelected) => {
      if (prevSelected.includes(claimName)) {
        return prevSelected.filter((name) => name !== claimName);
      } else {
        return [...prevSelected, claimName];
      }
    });
  };

  const getRoleClaimById = async (id) => {
    try {
      const response = await axios.get(`${apiUrl}/rolemanager/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status === true) {
        setRoles(response?.data?.role);
        setSelectedClaims(response?.data?.roleClaims?.map((claim) => claim?.value));
      }

    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setSuccesMessage(error?.response?.data?.title)
        setSuccessPopup(true)
      } else {
        setErrorMessage(error?.response?.data?.message || "Something went wrong");
      }
    }
  };

  useEffect(() => {
    if (id) {
      getRoleClaimById(id);
    }
  }, [id]);

  return (
    <Box>
      <Container>
        <Box sx={{ mt: "60px" }}>
          <Typography
            variant="body1"
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <ArrowLeft size={15} />
            Back
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h5">
              {location?.state ? "Edit Role" : "Add Role"}
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Grid container spacing={2} mt={2}>
                {/* Role Name */}
                <Grid item xs={12}>
                  <TextField
                    label="Role Name"
                    name="name"
                    value={roles?.name}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    required
                  />
                </Grid>

                {/* Description */}
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    name="description"
                    value={roles?.description}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    fullWidth
                    size="small"
                    required
                  />
                </Grid>

                {/* Is Active */}
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isActive"
                        checked={roles?.isActive}
                        onChange={handleChange}
                      />
                    }
                    label="Is Active"
                  />
                </Grid>

                {/* Is Deleted */}
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isDeleted"
                        checked={roles?.isDeleted}
                        onChange={handleChange}
                      />
                    }
                    label="Is Deleted"
                  />
                </Grid>
                {/* Error Message */}
                <Grid item xs={12}>
                  {errorMessage && (
                    <Typography color="error" textAlign="center" sx={{ mb: 2 }}>
                      {errorMessage}
                    </Typography>
                  )}
                </Grid>
                {/* Claims Checkboxes */}
                {
                  isEditing ? (
                    modules?.length > 0 && Object.keys(claimsGroup).map((moduleId) => {
                      const module = modules.find(m => m.id === parseInt(moduleId));
                      return (
                        <Grid item xs={12} key={moduleId}>
                          <Typography variant="h6">{module ? module?.module : "Unknown Module"}</Typography>
                          {claimsGroup[moduleId].map((claim) => (
                            <FormControlLabel
                              key={claim.id}
                              control={
                                <Checkbox
                                  checked={selectedClaims.includes(claim.policyName)}
                                  onChange={() => handleClaimChange(claim.policyName)}
                                />
                              }
                              label={claim.policyName}
                            />
                          ))}
                        </Grid>
                      );
                    })
                  ) : null
                }
                {/* Submit Button */}
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button variant="contained" type="submit">
                      {location?.state ? "Edit Role" : "Add Role"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Container>
      {succesMessage ?
        <SuccessPopup
          open={successPopup}
          onClose={closePopup}
          message={succesMessage}
        /> : ""}
    </Box>
  );
};
export default AddRoles;