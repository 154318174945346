import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../Context/Context";
import { ArrowLeft } from "lucide-react";
import SuccessPopup from "../../Common/SuccessPopup";
import apiUrl from "../../Config";

const TrainingVideos = () => {
  const location = useLocation();
  const videoId = location?.state?.id;
  const navigate = useNavigate();

  const {
    languages,
    serviceTypes,
    token,
    successPopup,
    succesMessage,
    setSuccessPopup,
    closePopup,
    setSuccesMessage,
  } = useContext(Context);

  const [formData, setFormData] = useState({
    Title: "",
    Description: "",
    LanguageId: "",
    ServiceId: "",
    videoFilePath: "",
    thumbnailFilePath: "",
    files: { fileDetails: [], fileTypes: [] },
  });

  const [errorMessage, setErrorMessage] = useState("");

  // Fetch video details if editing
  const fetchVideo = async () => {
    if (videoId) {
      try {
        const response = await axios.get(
          `${apiUrl}/trainingvideo/getvideo?id=${videoId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const videoData = response.data.video;

        setFormData({
          Title: videoData.title,
          Description: videoData.description,
          LanguageId: videoData.languageId,
          ServiceId: videoData.serviceId,
          videoFilePath: apiUrl + '' + videoData.filePath,
          thumbnailFilePath: apiUrl + '/' + videoData.thumbImageFilePath || "",
          files: {
            fileDetails: [videoData.filePath, videoData.thumbImageFilePath],
            fileTypes: ["TrainingVideo", "TrainingVideoThumbnail"],
          },
        });
      } catch (error) {
        if (error?.response?.data?.status === 401) {
          setSuccesMessage(error?.response?.data?.title)
          setSuccessPopup(true)
        } else {
          setErrorMessage(error?.response?.data?.message || "Something went wrong");
        }
      }
    }
  };

  useEffect(() => {
    fetchVideo();
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrorMessage("");
  };

  // Handle file changes
  const handleFileChange = (event, index, fileType) => {
    const file = event.target.files[0];
    const fileURL = URL.createObjectURL(file);

    setFormData((prev) => {
      const updatedFiles = { ...prev.files };
      updatedFiles.fileDetails[index] = file;
      updatedFiles.fileTypes[index] = fileType;

      if (fileType === "TrainingVideo") {
        return { ...prev, videoFilePath: fileURL, files: updatedFiles };
      } else if (fileType === "TrainingVideoThumbnail") {
        return { ...prev, thumbnailFilePath: fileURL, files: updatedFiles };
      }

      return { ...prev, files: updatedFiles };
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();

    data.append("Title", formData.Title);
    data.append("Description", formData.Description);
    data.append("LanguageId", formData.LanguageId);
    data.append("ServiceId", formData.ServiceId);

    if (videoId) {
      data.append("id", videoId);
    }

    const fileDetails = formData.files?.fileDetails || [];
    const fileTypes = formData.files?.fileTypes || [];

    fileDetails.forEach((file, index) => {
      if (file) {
        data.append(`Files[${index}].FileDetails`, file);
        data.append(`Files[${index}].FileType`, fileTypes[index]);
      }
    });

    try {
      const endpoint = videoId
        ? `${apiUrl}/trainingvideo/updatevideo`
        : `${apiUrl}/trainingvideo/createvideo`;

      const response = await axios.post(endpoint, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response?.data?.status === true) {
        setSuccesMessage(response?.data?.message);
        setSuccessPopup(true);
        setTimeout(() => {
          setSuccesMessage("");
          navigate(-1);
        }, 3000);

      } else {
        setErrorMessage(response?.data?.message);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setSuccesMessage(error?.response?.data?.title)
        setSuccessPopup(true)
      } else {
        setErrorMessage(error?.response?.data?.message || "Something went wrong");
      }
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 2, mt: 3 }}>
      <Typography
        variant="body1"
        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => navigate(-1)}
      >
        <ArrowLeft size={15} />
        Back
      </Typography>
      <Typography variant="h4" sx={{ textAlign: "center", mb: 3 }}>
        {videoId ? "Edit Training Video" : "Create Training Video"}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Title"
            name="Title"
            value={formData?.Title}
            onChange={handleInputChange}
            size="small"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Description"
            name="Description"
            value={formData?.Description}
            onChange={handleInputChange}
            size="small"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Language"
            name="LanguageId"
            select
            size="small"
            value={formData?.LanguageId}
            onChange={handleInputChange}
            fullWidth
            required
          >
            {languages.map((lang) => (
              <MenuItem key={lang?.id} value={lang?.id}>
                {lang?.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Service Type"
            name="ServiceId"
            select
            size="small"
            value={formData?.ServiceId}
            onChange={handleInputChange}
            fullWidth
            required
          >
            {serviceTypes.map((service) => (
              <MenuItem key={service?.id} value={service?.id}>
                {service?.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid container spacing={2} m={1}>
          {/* Video File */}
          <Grid item xs={12} sm={6}>
            <Typography>Video File</Typography>
            <Box sx={{ mt: "10px" }}>
              {formData.videoFilePath && (
                <video width="200px" controls>
                  <source src={formData?.videoFilePath} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </Box>
            <Box>
              <input
                type="file"
                onChange={(e) => handleFileChange(e, 0, "TrainingVideo")}
              />
            </Box>

          </Grid>

          {/* Thumbnail File */}
          <Grid item xs={12} sm={6}>
            <Typography>Thumbnail File</Typography>
            <Box sx={{ margin: "10px" }}>
              {formData.thumbnailFilePath && (
                <img
                  src={formData?.thumbnailFilePath}
                  alt="Thumbnail Preview"
                  style={{ width: "200px", height: "100px" }}
                />
              )}
            </Box>
            <Box>
              <input
                type="file"
                onChange={(e) => handleFileChange(e, 1, "TrainingVideoThumbnail")}
              />
            </Box>


          </Grid>

        </Grid>


        {errorMessage && (
          <Grid item xs={12}>
            <Typography color="error">{errorMessage}</Typography>
          </Grid>
        )}
        <Grid item xs={12} >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button type="submit" variant="contained" >
              {videoId ? "Update Video" : "Create Video"}
            </Button>
          </Box>
        </Grid>
      </Grid>
      {succesMessage ?
        <SuccessPopup
          open={successPopup}
          onClose={closePopup}
          message={succesMessage}
        /> : ""}
    </Box>
  );
};

export default TrainingVideos;
