// import React, { useRef, useState } from 'react';
// import { Box, Button } from '@mui/material';

// const HtmlEditor = () => {
//   const editorRef = useRef(null);
//   const [editorContent, setEditorContent] = useState('');

//   const handleFormatting = (command) => {
//     document.execCommand(command, false, null);
//   };

//   const handleContentChange = () => {
//     const updatedContent = editorRef.current.innerHTML;
//     setEditorContent(updatedContent);
//   };

//   return (
//     <Box>
//       <Box mb={1}>
//         <Button onClick={() => handleFormatting('bold')}>Bold</Button>
//         <Button onClick={() => handleFormatting('italic')}>Italic</Button>
//         <Button onClick={() => handleFormatting('underline')}>Underline</Button>
//         <Button onClick={() => handleFormatting('insertUnorderedList')}>Bullet Points</Button>
//         <Button onClick={() => handleFormatting('insertOrderedList')}>Numbered Points</Button>
//       </Box>
//       <Box
//         ref={editorRef}
//         contentEditable
//         suppressContentEditableWarning
//         onInput={handleContentChange}
//         sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '8px', minHeight: '100px' }}
//       />
//     </Box>
//   );
// };

// export default HtmlEditor;


import React, { useEffect, useRef } from 'react';
import { Box, Button } from '@mui/material';

const HtmlEditor = ({ value, onChange, placeholder }) => {
  const editorRef = useRef(null);

  const handleFormatting = (command) => {
    document.execCommand(command, false, null);
  };

  const handleContentChange = () => {
    if (editorRef.current) {
      const updatedContent = editorRef.current.innerHTML;
      onChange && onChange(updatedContent); // Call the parent's onChange
    }
  };

  // Ensure the editor reflects the `value` only when it changes from outside
  useEffect(() => {
    if (editorRef.current && editorRef.current.innerHTML !== value) {
      editorRef.current.innerHTML = value || ''; // Update content only when necessary
    }
  }, [value]);

  return (
    <Box>
      {/* Toolbar */}
      <Box mb={1}>
        <Button onClick={() => handleFormatting('bold')}>Bold</Button>
        <Button onClick={() => handleFormatting('italic')}>Italic</Button>
        <Button onClick={() => handleFormatting('underline')}>Underline</Button>
        <Button onClick={() => handleFormatting('insertUnorderedList')}>Bullet Points</Button>
        <Button onClick={() => handleFormatting('insertOrderedList')}>Numbered Points</Button>
      </Box>
      {/* Editable Box */}
      <Box
        ref={editorRef}
        contentEditable
        suppressContentEditableWarning
        onInput={handleContentChange}
        placeholder={placeholder}
        sx={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          padding: '8px',
          minHeight: '100px',
          maxHeight:'200px',
          direction: 'ltr',
          textAlign: 'left',
          overflowY:'scroll'
        }}
      />
    </Box>
  );
};

export default HtmlEditor;

   