
import React, { useState, useEffect, useContext } from "react";
import { TextField, Button, MenuItem, Box, Grid, Typography, Modal } from "@mui/material";
import axios from "axios";
import { Context } from "../../Context/Context";
import apiUrl from "../../Config";
import SuccessPopup from '../../Common/SuccessPopup';

const UserProfile = ({ open, onClose }) => {
    const { userProfile, token, successPopup,
        succesMessage,
        getUserProfile,
        setSuccessPopup,
        closePopup,
        setSuccesMessage } = useContext(Context);
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        birthDate: "",
        gender: "",
    });
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((prev) => ({ ...prev, [name]: value }));
    };

    const formattedBirthDate = userData?.birthDate
        ? new Date(userData?.birthDate).toISOString().split("T")[0]
        : "";

    const handleSubmit = async (e) => {
        e.preventDefault();
        const userFormData = new FormData();
        userFormData.append("FirstName", userData?.firstName);
        userFormData.append("LastName", userData?.lastName);
        userFormData.append("DateOfBirth", userData?.birthDate);
        userFormData.append("Gender", userData?.gender);

        try {
            const response = await axios.post(
                apiUrl + "/users/updateuserbytoken",
                userFormData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Accept: "*/*",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response?.data?.status === true) {
                setSuccesMessage(response?.data?.message)
                setSuccessPopup(true)
                getUserProfile();
            }
            setIsEditing(false);
            // onClose();
        } catch (error) {
            if (error?.response?.data?.status === 401) {
                setSuccesMessage(error?.response?.data?.title)
                setSuccessPopup(true)
            } else {
                setError(error?.response?.data?.message || "Something went wrong");
            }
        }
    };

    useEffect(() => {
        setUserData(userProfile?.userdetails);
    }, [userProfile?.userdetails]);

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-user-profile-title"
                aria-describedby="modal-user-profile-description"
            >
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "100vh" }}
                >
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                        <Box component="form" onSubmit={handleSubmit} >
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    bgcolor: "background.paper",
                                    borderRadius: 2,
                                    boxShadow: 24,
                                    p: 4,
                                    width: "400px",
                                }}
                            >


                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" sx={{ textAlign: "center" }}>User Profile</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            size="small"
                                            required
                                            label="First Name"
                                            name="firstName"
                                            value={userData?.firstName}
                                            onChange={handleChange}
                                            disabled={!isEditing}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            size="small"
                                            label="Last Name"
                                            name="lastName"
                                            value={userData?.lastName}
                                            onChange={handleChange}
                                            disabled={!isEditing}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            size="small"
                                            label="Date of Birth"
                                            name="birthDate"
                                            type="date"
                                            value={formattedBirthDate}
                                            onChange={handleChange}
                                            disabled={!isEditing}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            size="small"
                                            label="Gender"
                                            name="gender"
                                            value={userData?.gender}
                                            onChange={handleChange}
                                            disabled={!isEditing}
                                            select
                                            required
                                            fullWidth
                                        >
                                            <MenuItem value={0}>Male</MenuItem>
                                            <MenuItem value={1}>Female</MenuItem>
                                            <MenuItem value={2}>Others</MenuItem>
                                        </TextField>
                                    </Grid>
                                    {error && (
                                        <Grid item xs={12}>
                                            <Typography variant="body2" color="error">
                                                {error}
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                        {!isEditing ? (
                                            <Button variant="contained" onClick={() => setIsEditing(true)}>
                                                Edit
                                            </Button>
                                        ) : (
                                            <>
                                                <Button variant="contained" type="submit">
                                                    Submit
                                                </Button>
                                            </>
                                        )}
                                        <Button onClick={onClose} color="error" variant="contained">
                                            Close
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Modal>
            {succesMessage ?
                <SuccessPopup
                    open={successPopup}
                    onClose={closePopup}
                    message={succesMessage}
                /> : ""}
        </>
    );
};

export default UserProfile;


