
import React, { useContext, useEffect, useState } from "react";
import {
  Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, Button, FormControl, InputLabel, Select, MenuItem, Divider, CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Context } from "../../Context/Context";
import apiUrl from "../../Config";
import axios from "axios";
import { Pencil, Trash2 } from "lucide-react";
import DeleteConfirm from "../../Common/DeleteConfirm";
import SuccessPopup from "../../Common/SuccessPopup";
const AllRoles = () => {
  const { roles, getAllRoles, token, successPopup, succesMessage, setSuccessPopup, closePopup, setSuccesMessage,
    isConfirmDeleteModelOpen, setIsConfirmDeleteModelOpen, itemIdToDelete, setItemIdToDelete
  } = useContext(Context);
  const navigate = useNavigate();
  const [filteredRoles, setFilteredRoles] = useState(roles);
  const [searchRole, setSearchRole] = useState("");
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(true)
  const handleRoleFilterChange = (event) => {
    const roleName = event.target.value;
    setSearchRole(roleName);
    setFilteredRoles(roleName ? roles.filter((role) => role.name === roleName) : roles);
  };
  const handleDeleteClick = (id) => {
    setIsConfirmDeleteModelOpen(true);
    setItemIdToDelete(id);

  };


  const handleRoleDelete = async () => {
    setIsConfirmDeleteModelOpen(false);
    try {
      const response = await axios.delete(`${apiUrl}/rolemanager/${itemIdToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setSuccesMessage("Role deleted successfull")
        setSuccessPopup(true)
        getAllRoles();
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setSuccesMessage(error?.response?.data?.title)
        setSuccessPopup(true)
      } else {
        setError(error?.response?.data?.message || "Something went wrong");
      }
    }
  };

  const handleEditRole = (id) => {
    navigate('/addrole', { state: { id: id } })
  };

  useEffect(() => {
    const fetchRoles = async () => {
      setLoading(true);
      try {
        await getAllRoles();
        setFilteredRoles(roles);
      } catch (err) {
        setError("Failed to fetch roles");
      } finally {
        setLoading(false);
      }
    };

    fetchRoles();
    getAllRoles();
  }, []);
  useEffect(() => {
    setFilteredRoles(roles);
  }, [roles]);

  return (
    <Box sx={{ mt: "80px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2, alignItems: "center" }}>
        <Typography variant="h4" >
          Roles List
        </Typography>
        <Box sx={{ display: "flex", gap: "10px", }}>
          <FormControl size="small" sx={{ width: "150px" }}>
            <InputLabel>Filter by Role</InputLabel>
            <Select value={searchRole} onChange={handleRoleFilterChange} label="Filter by Role">
              <MenuItem value="">All Roles</MenuItem>
              {roles?.map((role) => (
                <MenuItem key={role.id} value={role.name}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" onClick={() => {
            navigate('/addrole')
          }} sx={{ width: "200px" }}>
            Add Role
          </Button>
        </Box>
      </Box>
      <Divider />
      {error && <Typography variant="h4" sx={{ color: "red" }}>{error}</Typography>}
      {loading ? (<Box display="flex" justifyContent="center" alignItems="center" height="100px" mt={2}>
        <CircularProgress />
      </Box>) : (<TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow >
              <TableCell align="">S.No</TableCell>
              <TableCell align="">Name</TableCell>
              <TableCell align="">Description</TableCell>
              <TableCell align="">Active</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody >
            {filteredRoles.map((role, index) => (
              <TableRow key={role.id}>
                <TableCell align="">{index + 1}</TableCell>
                <TableCell align="">{role.name}</TableCell>
                <TableCell align="">{role.description}</TableCell>
                <TableCell align="">{role.isActive ? "True" : "False"}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleEditRole(role.id)} color="primary" sx={{ padding: "0px" }}>
                    <Pencil size={18} />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteClick(role.id)} color="error" sx={{ padding: "0px", marginLeft: "10px" }} >
                    <Trash2 size={18} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>)}
      {succesMessage ?
        <SuccessPopup
          open={successPopup}
          onClose={closePopup}
          message={succesMessage}
        /> : ""}
      <DeleteConfirm
        open={isConfirmDeleteModelOpen}
        onClose={() => setIsConfirmDeleteModelOpen(false)}
        onConfirm={handleRoleDelete}

      />
    </Box>
  );
};
export default AllRoles;
