
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, CircularProgress, Divider } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../Context/Context';
import apiUrl from '../../Config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const BookingList = () => {
  const { token } = useContext(Context);
  const [bookings, setBookings] = useState([]);
  const [errors, setErrors] = useState(null);
  const [bookingId, setBookingId] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

  const fetchBookings = async () => {
    let payload = {
      pageSize: 5,
      pageNumber: pageNumber,
    };

    if (bookingId.trim() !== '') {
      payload.bookingId = bookingId;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `${apiUrl}/booking/searchbookings`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setHasMore(response.data.bookings.length === 5);
      setBookings(response.data.bookings);
    } catch (error) {
      setErrors('Failed to fetch booking data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearchInputChange = (event) => {
    setBookingId(event.target.value);
  };

  const handleSearchClick = () => {
    setPageNumber(1);
    fetchBookings();
  };

  const handleNextPage = () => {
    if (hasMore) {
      setPageNumber((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber((prev) => prev - 1);
    }
  };

  useEffect(() => {
    fetchBookings();
  }, [pageNumber]);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" fontWeight={600}>
          Bookings List
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
          <TextField
            label="Search by Booking ID"
            variant="outlined"
            size="small"
            sx={{ width: '300px' }}
            value={bookingId}
            onChange={handleSearchInputChange}
          />
          <Button
            variant="contained"
            onClick={handleSearchClick}
          >
            Search
          </Button>
        </Box>
      </Box>

      <Divider sx={{ mb: 3 }} />

      {loading ? (<Box display="flex" justifyContent="center" alignItems="center" height="100px" mt={2}>
        <CircularProgress />
      </Box>) :
        (
          <Box>
            {/* Table Section */}
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Booking ID
                    </TableCell>
                    <TableCell>
                      User Name
                    </TableCell>
                    <TableCell>
                      Professional Name
                    </TableCell>
                    <TableCell>
                      Booking Created Date
                    </TableCell>
                    <TableCell align='right'>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bookings?.map((booking, index) => (
                    <TableRow key={index}>
                      <TableCell>{booking?.booking?.bookingID}</TableCell>
                      <TableCell>{`${booking?.userFirstName} ${booking?.userLastName}`}</TableCell>
                      <TableCell>{`${booking?.workerFirstName} ${booking?.workerLastName}`}</TableCell>
                      <TableCell>
                        {new Date(booking?.booking?.createDate).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}
                      </TableCell>
                      <TableCell align='right'>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => navigate(`/bookingDetails/${booking?.booking?.bookingID}`)}
                        >
                          View Details
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Pagination Section */}
            <Box display="flex" justifyContent="center" sx={{ mt: 2, gap: 2 }}>
              <Button variant="outlined" onClick={handlePreviousPage} disabled={pageNumber === 1}>
                Previous
              </Button>
              <Button variant="outlined" onClick={handleNextPage} disabled={!hasMore}>
                Next
              </Button>
            </Box>
          </Box>
        )}

      {/* Error Section */}
      {errors && <Typography color="error">{errors}</Typography>}
    </Box >
  );
};

export default BookingList;


