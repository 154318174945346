import {
  Box,
  Button,
  Container,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Register = () => {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    role: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  // const [successMessage, setSuccessMessage] = useState("");
  const roles = [
    { value: "Admin", label: "Admin" },
    { value: "User", label: "User" },
    { value: "Accountant", label: "Accountant" },
    {
      value: "CustomerSupportRepresentative",
      label: "Customer Support Representative",
    },
    { value: "Worker", label: "Worker" },
    { value: "Manager", label: "Manager" },
    { value: "Trainer", label: "Trainer" },
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Only allow numeric values for phone and restrict to 10 digits
    if (name === "phone") {
      if (/^\d{0,10}$/.test(value)) {
        setUserData({
          ...userData,
          [name]: value,
        });
      }
    } else {
      setUserData({
        ...userData,
        [name]: value,
      });
    }
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setErrorMessage("");
    try {
      const response = await axios.post(
        "https://api.sevaki.in/users/registeruser",
        userData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Registration Successful:", response.data);
      // localStorage.setItem("user", JSON.stringify(userData));
      navigate("/login");
    } catch (error) {
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data.message || "Registration failed");
      } else {
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <>
      <Container>
        <Box>
          <Typography variant="h4" textAlign="center" m="30px">
            Register
          </Typography>
          <Box>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: 400,
                margin: "0 auto",
              }}
            >
              <TextField
                size="small"
                fullWidth
                label="First Name"
                name="firstName"
                type="text"
                variant="outlined"
                value={userData.firstName}
                onChange={handleChange}
                required
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      border: "1px solid #E4E4E4",
                    },
                  },
                }}
              />
              <TextField
                size="small"
                fullWidth
                label="Last Name"
                name="lastName"
                type="text"
                variant="outlined"
                value={userData.lastName}
                onChange={handleChange}
                required
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      border: "1px solid #E4E4E4",
                    },
                  },
                }}
              />
              <TextField
                size="small"
                fullWidth
                label="Mobile Number"
                name="phone"
                type="number"
                variant="outlined"
                value={userData.phone}
                onChange={handleChange}
                required
                inputProps={{ maxLength: 10 }}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      border: "1px solid #E4E4E4",
                    },
                  },
                }}
              />
              <TextField
                size="small"
                fullWidth
                label="Email"
                name="email"
                type="email"
                variant="outlined"
                value={userData.email}
                onChange={handleChange}
                required
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      border: "1px solid #E4E4E4",
                    },
                  },
                }}
              />
              <TextField
                fullWidth
                size="small"
                type="password"
                label="Password"
                name="password"
                variant="outlined"
                onChange={handleChange}
                required
                value={userData.password}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      border: "1px solid #E4E4E4",
                    },
                  },
                }}
              />

              <TextField
                fullWidth
                size="small"
                select
                name="role"
                label="Role"
                value={userData.role}
                variant="outlined"
                onChange={handleChange}
                required
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      border: "1px solid #E4E4E4",
                    },
                  },
                }}
              >
                <MenuItem value="">
                  <em>-- Select Role --</em>
                </MenuItem>
                {roles.map((role, index) => (
                  <MenuItem key={index} value={role.value}>
                    {role.label}
                  </MenuItem>
                ))}
              </TextField>

              {errorMessage && (
                <Typography color="error" textAlign="center" sx={{ mb: 2 }}>
                  {errorMessage}
                </Typography>
              )}

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button type="submit" variant="contained" color="primary">
                  Register
                </Button>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography variant="h6">
                  <Link
                    to="/"
                    style={{ textDecoration: "none", color: "#000000" }}
                  >
                    Already have an account? Login
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Register;
