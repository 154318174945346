import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    CircularProgress,
    Typography,
    Checkbox,
    FormControlLabel,
    Button,
    TextField,
    Modal, IconButton,
    Grid,
    Divider,
} from '@mui/material';
import axios from 'axios';
import { Context } from '../../Context/Context';
import apiUrl from '../../Config';
import SuccessPopup from '../../Common/SuccessPopup';
import { Pencil, Trash2 } from 'lucide-react';
import DeleteConfirm from '../../Common/DeleteConfirm';


const ServiceFields = () => {
    const { token, zones, successPopup,
        succesMessage,
        setSuccessPopup,
        closePopup,
        setSuccesMessage,
        isConfirmDeleteModelOpen,
        setIsConfirmDeleteModelOpen,
        itemIdToDelete,
        setItemIdToDelete } = useContext(Context);

    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState('');
    const [subservices, setSubservices] = useState([]);
    const [selectedSubservice, setSelectedSubservice] = useState('');
    const [fields, setFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [seleteFieldName, setSeleteFieldName] = useState("")

    const [openAddModal, setOpenAddModal] = useState(false);
    const [newField, setNewField] = useState({
        fieldName: "",
        value: '',
        description: '',
        isActive: true,
        isDeleted: false,
    });

    const [openEditModal, setOpenEditModal] = useState(false);
    const [editFieldData, setEditFieldData] = useState(null);
    const [selectedZone, setSelectedZone] = useState('');

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await axios.get(`${apiUrl}/servicetypes/getservicetypes`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setServices(response.data);
            } catch (error) {
                console.error('Error fetching services:', error);
            }
        };
        fetchServices();
    }, []);


    const handleServiceChange = async (event) => {
        const serviceId = event.target.value;
        setSelectedService(serviceId);
        setSelectedSubservice('')
        setSelectedZone('')
    };

    const handleSubserviceChange = async (event) => {
        const subServiceId = event?.target?.value || selectedSubservice
        setSelectedSubservice(subServiceId);
        if (subServiceId) {
            setLoading(true);
            setError('');
            try {
                const response = await axios.get(`${apiUrl}/servicefields/getservicefielddropdownsbysubserviceid?subServiceId=${subServiceId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setFields(response.data.serviceFields || []);
            } catch (err) {
                setError('Error fetching fields.');
                setFields([]);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleAddNewField = () => {
        setOpenAddModal(true);
        setSeleteFieldName("")
    };

    const handleCloseAddModal = () => {
        setOpenAddModal(false);
    };

    const handleFieldChange = (event) => {
        const { name, value } = event.target;
        setNewField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmitNewField = async (e) => {
        e.preventDefault()
        try {
            // Include the additional fields in the `newField` object
            const fieldData = {
                ...newField,
                id: 0,
                serviceId: selectedService,
                subServiceId: selectedSubservice ? selectedSubservice : 0,
            };

            // Make the API request
            const response = await axios.post(`${apiUrl}/servicefields/createservicefield`, fieldData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response?.data?.status === true) {
                setSuccesMessage("Service field created successfully");
                setSuccessPopup(true)
                setOpenAddModal(false);
                setNewField({
                    fieldName: '',
                    value: '',
                    description: '',
                    isActive: true,
                    isDeleted: false,
                });
                handleSubserviceChange(selectedSubservice)
            }
        } catch (error) {
            console.error('Error submitting new field:', error);
        }
    };


    const handleEditFieldClick = (field) => {
        setEditFieldData(field);
        setOpenEditModal(true);
    };

    const handleCloseEditModal = () => {
        setOpenEditModal(false);
        setEditFieldData(null);
    };

    const handleEditFieldChange = (event) => {
        const { name, value } = event.target;
        setEditFieldData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmitEditField = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(`${apiUrl}/servicefields/updateservicefield`, editFieldData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response?.data?.status === true) {
                setSuccesMessage("Service field edited successfully");

                setSuccessPopup(true)
                setOpenEditModal(false);
                handleSubserviceChange()
            }
        } catch (error) {
            console.error('Error updating field:', error);
        }
    };


    const handleCheckboxChange = (fieldId, fieldName, value) => {
        setFields((prevFields) =>
            prevFields.map((field) =>
                field.id === fieldId ? { ...field, [fieldName]: value } : field
            )
        );
    };

    const handleAddCheckboxChange = (field, value) => {
        setNewField((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleEditCheckboxChange = (field, value) => {
        setEditFieldData((prevState) => ({
            ...prevState,
            [field]: value,
        }));
    };


    const handleFieldDelete = (fieldID) => {
        setIsConfirmDeleteModelOpen(true)
        setItemIdToDelete(fieldID)
    }

    const handleDeleteClick = async () => {
        setIsConfirmDeleteModelOpen(false)
        try {
            await axios.delete(apiUrl + `/servicefields/${itemIdToDelete}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setFields((prevFields) => prevFields.filter((field) => field.id !== itemIdToDelete));
        } catch (err) {
            setError('Error deleting field.');
        }
    };

    const openValuePopUp = (name) => {
        setSeleteFieldName(name);
        setOpenAddModal(true);
        setNewField(prev => ({ ...prev, fieldName: name }));
    };

    const handleChange = async (event) => {
        const zoneId = event.target.value
        setSelectedZone(event.target.value);
        setSubservices([]);
        setSelectedSubservice('');

        if (zoneId) {
            setLoading(true);
            setError('');
            try {
                const response = await axios.get(`${apiUrl}/servicetypes/0/getservicesubtypesbyserviceidwithzoneid?serviceId=${selectedService}&zoneId=${zoneId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.data.subservices.length > 0) {
                    setSubservices(response.data.subservices);
                } else {
                    setError('No subservices available for the selected service.');
                }
            } catch (err) {
                setError('Error fetching subtypes.');
            } finally {
                setLoading(false);
            }
        }

    }

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 2,
                }}
            >

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 3,
                }}>
                    <Box>
                        <Typography variant="h5" color="initial" mb={1}>Select a Service</Typography>
                        <FormControl sx={{ width: 200 }} size='small'>
                            <InputLabel id="service-select-label">Select Service</InputLabel>
                            <Select
                                label="Select Service"
                                id="service-select"
                                value={selectedService}
                                onChange={handleServiceChange}
                            >
                                {services?.map((service) => (
                                    <MenuItem key={service.id} value={service.id}>
                                        {service.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box>
                        <Typography variant="h5" color="initial" mb={1}>Select a Zone</Typography>
                        <FormControl size='small'>
                            <InputLabel id="zone-select-label">Select Zone</InputLabel>
                            <Select
                                label="Select Zone"
                                id="zone-select"
                                value={selectedZone}
                                onChange={handleChange}
                                sx={{ width: '200px' }}
                            >
                                <MenuItem value="" disabled>
                                    Select Zone
                                </MenuItem>
                                {zones?.map((zone) => (
                                    <MenuItem key={zone.id} value={zone.id}>
                                        {zone.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    {subservices.length > 0 && (
                        <Box>
                            <Typography variant="h5" mb={1}>Select a SubService</Typography>
                            <FormControl sx={{ width: 200 }} size='small'>
                                <InputLabel id="subservice-select-label">Select Subservice</InputLabel>
                                <Select
                                    label="Select Subservice"
                                    id="subservice-select"
                                    value={selectedSubservice}
                                    onChange={handleSubserviceChange}
                                >
                                    {subservices?.map((subservice) => (
                                        <MenuItem key={subservice.id} value={subservice.id}>
                                            {subservice.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                </Box>

                {selectedSubservice && (
                    <Button
                        variant="contained"
                        onClick={handleAddNewField}
                        sx={{ whiteSpace: 'nowrap' }}
                    >
                        Add New Field
                    </Button>
                )}

                {loading && <CircularProgress size={24} />}

                {error && <Typography color="error">{error}</Typography>}
            </Box>

            <Divider sx={{ mb: 3, mt: 3 }} />

            <Box>
                {
                    selectedService && selectedZone && selectedSubservice && fields.length === 0 ? (
                        <Typography variant="h6" sx={{ textAlign: 'center', mt: 4, color: 'text.secondary' }}>
                            No fields available. Please add a new field.
                        </Typography>
                    ) :
                        fields.length > 0 ?
                            (
                                Object.keys(fields.reduce((acc, field) => {
                                    if (!acc[field.fieldName]) {
                                        acc[field.fieldName] = [];
                                    }
                                    acc[field.fieldName].push(field);
                                    return acc;
                                }, {})).map((fieldName) => {
                                    const fieldGroup = fields.reduce((acc, field) => {
                                        if (field.fieldName === fieldName) {
                                            acc.push(field);
                                        }
                                        return acc;
                                    }, []);

                                    return (
                                        <Box key={fieldName} sx={{ mb: 4, mt: 4 }}>
                                            <Box sx={{ display: 'flex', gap: 6, alignItems: 'center', mb: 2, justifyContent: 'space-between' }}>
                                                <Typography variant="h5" fontWeight={600}>
                                                    Fieldname: {fieldName}
                                                </Typography>
                                                <Button variant="contained" onClick={() => openValuePopUp(fieldName)}>
                                                    Add New Value
                                                </Button>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center',  gap: 2 }}>
                                                {fieldGroup.map((field) => (
                                                    <Box
                                                        key={field.id}
                                                        sx={{
                                                            display: 'flex', width: '100%', alignItems: 'center', justifyContent:'space-between', gap: 4, padding: 2, minWidth: '250px',
                                                            border: '1px solid #ddd', borderRadius: 2
                                                        }}
                                                    >
                                                        <Typography variant="body1" sx={{ fontWeight: 500, minWidth: '150px' }}>
                                                            <strong>Value:</strong> {field.value}
                                                        </Typography>
                                                        <Typography variant="body1"  sx={{ minWidth: '400px' }}>
                                                            <strong>Description:</strong> {field.description}
                                                        </Typography>

                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={field.isActive || false}
                                                                        onChange={(e) => handleCheckboxChange(field.id, 'isActive', e.target.checked)}
                                                                        disabled='true'
                                                                        sx={{
                                                                            color: "#7E60BF",
                                                                            "&.Mui-disabled": {
                                                                                color: "#7E60BF",
                                                                            },
                                                                        }}
                                                                    />
                                                                }
                                                                label="Active"
                                                            />
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={field.isDeleted || false} // Default to false if undefined
                                                                        onChange={(e) => handleCheckboxChange(field.id, 'isDeleted', e.target.checked)}
                                                                        disabled='true'
                                                                        sx={{
                                                                            color: "#7E60BF",
                                                                            "&.Mui-disabled": {
                                                                                color: "#7E60BF",
                                                                            },
                                                                        }}
                                                                    />
                                                                }
                                                                label="Deleted"
                                                            />
                                                        </Box>

                                                        <Box sx={{ display: 'flex', gap: 1, justifyContent:'end' }}>
                                                            <IconButton aria-label="" onClick={() => handleEditFieldClick(field)} color='primary'>
                                                                <Pencil size={18} />

                                                            </IconButton>
                                                            <IconButton aria-label="" onClick={() => handleFieldDelete(field.id)} color='error'>
                                                                <Trash2 size={18} />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Box>

                                    );
                                })
                            ) : (
                                null
                            )
                }
            </Box>

            {/* Addmodel */}
            <Modal open={openAddModal} onClose={handleCloseAddModal}>
                <Grid container justifyContent='center' alignItems='center' height='100vh'>
                    <Box component='form' onSubmit={handleSubmitNewField}>
                        <Box
                            sx={{
                                position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400,
                                bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2,
                            }}
                        >
                            <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
                                Add New Field
                            </Typography>
                            <TextField fullWidth label="Field Name" name="fieldName"
                                value={newField.fieldName || seleteFieldName}
                                onChange={handleFieldChange}
                                sx={{ mb: 2 }}
                                required
                                size='small'
                            />
                            <TextField fullWidth label="Value" name="value"
                                value={newField.value} onChange={handleFieldChange}
                                sx={{ mb: 2 }}
                                required
                                size='small'
                            />
                            <TextField fullWidth label="Description" name="description"
                                value={newField.description} onChange={handleFieldChange}
                                sx={{ mb: 2 }}
                                required
                                size='small'
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={newField.isActive}
                                        onChange={(e) => handleAddCheckboxChange("isActive", e.target.checked)}
                                    />
                                }
                                label="Active"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={newField.isDeleted}
                                        onChange={(e) => handleAddCheckboxChange("isDeleted", e.target.checked)}
                                    />
                                }
                                label="Deleted"
                            />
                            <Box display="flex" justifyContent="flex-end" gap={2}>
                                <Button variant="contained" color="primary" type='submit'>
                                    Submit
                                </Button>
                                <Button variant="contained" color='error' onClick={handleCloseAddModal}>
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Modal>

            {/* editmodal */}
            <Modal open={openEditModal} onClose={handleCloseEditModal}>
                <Grid container justifyContent='center' alignItems='center' height='100vh'>
                    <Box component='form' onSubmit={handleSubmitEditField} >
                        <Box
                            sx={{
                                position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                                width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2,
                            }}
                        >
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                Edit Field
                            </Typography>
                            {editFieldData && (
                                <>
                                    <TextField fullWidth label="Field Name" name="fieldName"
                                        value={editFieldData.fieldName} onChange={handleEditFieldChange}
                                        sx={{ mb: 2 }}
                                        required
                                        size='small'
                                    />
                                    <TextField fullWidth label="Value" name="value"
                                        value={editFieldData.value} onChange={handleEditFieldChange}
                                        sx={{ mb: 2 }}
                                        required
                                        size='small'
                                    />
                                    <TextField fullWidth label="Description" name="description"
                                        value={editFieldData.description} onChange={handleEditFieldChange}
                                        sx={{ mb: 2 }}
                                        required
                                        size='small'
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={editFieldData.isActive}
                                                onChange={(e) => handleEditCheckboxChange("isActive", e.target.checked)}
                                            />
                                        }
                                        label="Active"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={editFieldData.isDeleted}
                                                onChange={(e) => handleEditCheckboxChange("isDeleted", e.target.checked)}
                                            />
                                        }
                                        label="Deleted"
                                    />
                                    <Box display="flex" justifyContent="flex-end" gap={2}>
                                        <Button variant="contained" color="primary" type='submit' >
                                            Update
                                        </Button>
                                        <Button variant="outlined" onClick={handleCloseEditModal}>
                                            Cancel
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Modal>

            <DeleteConfirm
                open={isConfirmDeleteModelOpen}
                onClose={() => setIsConfirmDeleteModelOpen(false)}
                onConfirm={handleDeleteClick}

            />

            {succesMessage ?
                <SuccessPopup
                    open={successPopup}
                    onClose={closePopup}
                    message={succesMessage}
                /> : ""}
        </Box>
    );
};

export default ServiceFields;