import {
  Box, Button, Checkbox, FormControlLabel, Grid, Modal, TextField, Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import apiUrl from "../../Config";
import ClearIcon from '@mui/icons-material/Clear';
import { Context } from "../../Context/Context";

import DeleteConfirm from "../../Common/DeleteConfirm";
import SuccessPopup from "../../Common/SuccessPopup";

const AddBankDetails = ({ open, onClose, userId }) => {
  const { token, successPopup,
    succesMessage,
    setSuccessPopup,
    closePopup,
    setSuccesMessage,
    isConfirmDeleteModelOpen, 
    setIsConfirmDeleteModelOpen,
     itemIdToDelete, 
     setItemIdToDelete } = useContext(Context);
  const [error, setError] = useState("");
  const [bankDetails, setBankDetails] = useState({
    id: "",
    accountNumber: "",
    ifscCode: "",
    accountName: "",
    isActive: true,
  });


  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [bankIdToDelete, setBankIdToDelete] = useState(null);

  // Handle change for bank details
  const handleChange = (e) => {
    const { name, value } = e.target;
    setBankDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    setError("");
  };

  // Handle checkbox change
  const handleCheckboxChange = () => {
    setBankDetails((prevDetails) => ({
      ...prevDetails,
      isActive: !prevDetails?.isActive,
    }));
  };

  // Get bank details by user Id
  const getBankDetailsById = async () => {
    try {
      const response = await axios.get(
        apiUrl + `/userbankaccount/getuserbankaccountbyuserid?userId=${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
      );
      if (response?.data?.status === true) {
        setBankDetails(response?.data?.userAccount);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setError(error?.response?.data?.title)
      } else {
        setError(error?.response?.data?.message || "Something went wrong");
      }
    }
  };



  // Handle submit for the form
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (bankDetails?.id) {
        const response = await axios.post(
          apiUrl + `/userbankaccount/updatebankaccountbyadmin?userId=${userId}`, bankDetails,
          {
            headers: {
              accept: "*/*",
              "Content-Type": "application/json-patch+json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response?.data?.status === true) {

          setSuccesMessage(response?.data?.message)
          setSuccessPopup(true);
        } else {
          setError(response?.data?.message)
        }

      } else {
        const response = await axios.post(apiUrl + `/userbankaccount/createbankaccountbyadmin?userId=${userId}`, bankDetails,
          {
            headers: {
              accept: "*/*",
              "Content-Type": "application/json-patch+json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response?.data?.status === true) {
          setSuccesMessage(response?.data?.message)
          setSuccessPopup(true);
          setTimeout(() => {
            setSuccesMessage("");
            getBankDetailsById()
          }, 3000);
        }
        else {
          setError(response?.data?.message)
        }
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setError(error?.response?.data?.title)
      } else {
        setError(error?.response?.data?.message || "Something went wrong");
      }
    }
  };


  const confirmDeleteBankId = () => {
    setItemIdToDelete(userId)
    setIsConfirmDeleteModelOpen(true)
  }

  const deleteBankDetails = async () => {
    setIsConfirmDeleteModelOpen(false)
    try {
      const response = await axios.delete(
        apiUrl + `/userbankaccount/${itemIdToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
      );
      if (response.data.status === true) {
        setSuccesMessage(response?.data?.message);
        setSuccessPopup(true);
        setTimeout(() => {
          setSuccesMessage("");
          onClose()
        }, 3000);
      }
      else {
        setError(response?.data?.message)
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setError(error?.response?.data?.title)
      } else {
        setError(error?.response?.data?.message || "Something went wrong");
      }

    }
  };

  useEffect(() => {
    if (userId) {
      getBankDetailsById();
    }
  }, [userId]);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100vh" }}
        >
          <Grid item xs={12} sm={8} md={6} lg={4}>

            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
                width: "400px",
              }}
            >

              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                <Typography variant="h5" align="center" gutterBottom>
                  Bank Details Form
                </Typography>
                <ClearIcon onClick={onClose} sx={{ cursor: "pointer" }} />
              </Box>
              <Box component="form" onSubmit={handleSubmit}>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Account Number"
                      type="number"
                      name="accountNumber"
                      value={bankDetails?.accountNumber}
                      onChange={handleChange}
                      required
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="IFSC Code"
                      name="ifscCode"
                      value={bankDetails?.ifscCode}
                      onChange={handleChange}
                      required
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Account Name"
                      name="accountName"
                      value={bankDetails?.accountName}
                      onChange={handleChange}
                      required
                      fullWidth
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={bankDetails?.isActive}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Is Active"
                    />
                  </Grid>

                  {error && (
                    <Grid item xs={12}>
                      <Typography color="error" variant="body1" align="center">
                        {error}
                      </Typography>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Button type="submit" variant="contained" color="primary">
                        {bankDetails?.id ? "Update Bank Details" : "Create Bank Details"}
                      </Button>
                      {bankDetails?.id && (
                        <Button
                          variant="contained"
                          color="error"
                          sx={{ marginLeft: "20px" }}
                          onClick={confirmDeleteBankId}
                        >
                          Delete Bank Details
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Modal>
      {succesMessage ?
        <SuccessPopup
          open={successPopup}
          onClose={closePopup}
          message={succesMessage}
        /> : ""}

      <DeleteConfirm
        open={isConfirmDeleteModelOpen}
        onClose={() => setIsConfirmDeleteModelOpen(false)}
        onConfirm={deleteBankDetails}

      />
    </>
  );
};

export default AddBankDetails;
