import { Box, Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import apiUrl from "../Config";

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle submit for forgot password
  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage("");
    setError("");

    try {
      const response = await axios.get(
        `${apiUrl}/forgotpassword?email=${formData.email}`
      );

      if (response.data.status === true) {
        setMessage(response.data.message || "Request successful!");
      }
    } catch (error) {
      setError(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          backgroundColor:"white",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Box
          sx={{
            // backgroundColor: "rgba(255, 255, 255, 0.9)",
            padding: 4,
            borderRadius: 2,
            // boxShadow: 3,
            maxWidth: 400,
            width: "100%",
            border:"1px solid #EBEBEB"
          }}
        >
          <Typography variant="h4" textAlign="center" mb={3}>
            Forgot Password
          </Typography>
          {message && (
            <Typography
              variant="body1"
              sx={{
                color: "green",
                textAlign: "center",
                marginBottom: 2,
              }}
            >
              {message}
            </Typography>
          )}
          {error && (
            <Typography
              variant="body1"
              sx={{
                color: "red",
                textAlign: "center",
                marginBottom: 2,
              }}
            >
              {error}
            </Typography>
          )}
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              size="small"
              fullWidth
              label="Email"
              name="email"
              type="email"
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
              required
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#EBEBEB",
                  },
                },
                mb: 2,
                border:"1px solid #EBEBEB"
              }}

   
            />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Submit
              </Button>
            </Box>

            <Box sx={{ mt: 3, textAlign: "center" }}>
              <Typography variant="h6">
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "#000000" }}
                >
                  Back
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ForgotPassword;
