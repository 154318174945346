import React from "react";
import { Route, Routes } from "react-router-dom";
import AddUser from "../Pages/User/AddUser";
import BookingList from "../Pages/Bookings/BookingList";
import Faq from "../Pages/Faq/Faq";
import Zone from "../Pages/Zone/Zone";
import Roles from "../Pages/Roles/Roles";
import AddRoles from "../Pages/Roles/AddRoles";
import TrainingVideos from "../Pages/TrainingVideos/TrainingVideos";
import AllTrainingVideos from "../Pages/TrainingVideos/AllTrainingVideos";
import Users from "../Pages/User/Users";
import ChangePassword from "../Forms/ChangePassword";
import Membership from "../Pages/Memberships/Membership";
import DisplayServices from "../Pages/Services/SubServices/DisplayServices";
import DisplaySubServices from "../Pages/Services/SubServices/DisplaySubServices";
import Languages from "../Pages/Languages/Languages";
import DisplayMiniServices from "../Pages/Services/SubServices/DisplayMiniServices";
import ServiceSteps from "../Pages/Services/SubServices/ServiceSteps";
import UserProfile from "../Pages/User/UserProfile";
import ServiceFields from "../Pages/Services/ServiceFields";
import Policies from "../Pages/Policies/Policies";
import BookingDetails from "../Pages/Bookings/BookingDetails";
import WeeklyRoutines from "../Pages/WeeklyRoutines/WeeklyRoutines";
import DisplayAddons from "../Pages/Services/SubServices/DisplayAddons";
import HtmlEditor from "../Common/HtmlEditor";
import BookingTransaction from "../Pages/Bookings/BookingTransaction";
import Dashboard from "../Pages/Dasboard/Dashboard";
import Prices from "../Pages/Prices/Prices";
import AddPrice from "../Pages/Prices/AddPrice";


const PageInnerRoutes = () => {
  return (
    <>
      <Routes>

        <Route path="/" element={<Dashboard/>}/>
        <Route path="/servicefields" element={<ServiceFields />} />
        <Route path="/bookingslist" element={<BookingList />} />
        <Route path="/bookingDetails/:id" element={<BookingDetails />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/zones" element={<Zone />} />
        <Route path="/membershipPlans" element={<Membership />} />
        <Route path="/languages" element={<Languages />} />

        <Route path="/changepassword" element={<ChangePassword />} />

        {/* user  routes */}
        <Route path="/users" element={<Users />} />
        <Route path="/adduser" element={<AddUser />} />
        <Route path="/porfile" element={<UserProfile />} />

        {/* user Roles */}

        <Route path="/role" element={<Roles />} />
        <Route path="/addrole" element={<AddRoles />} />

        <Route path="/prices" element={<Prices />} />
        <Route path="/addPrice" element={<AddPrice/>}/>


        <Route path="/policies" element={<Policies />} />

        <Route path="/addtraingvideo" element={<TrainingVideos />} />
        <Route path="/trainingvideos" element={<AllTrainingVideos />} />

        <Route path="/displayServices" element={<DisplayServices />} />
        <Route path="/displaySubServices" element={<DisplaySubServices />} />
        <Route path="/displayMiniServices" element={<DisplayMiniServices />} />
        <Route path="/displayAddOns" element={<DisplayAddons />} />
        <Route path="/serviceSteps" element={<ServiceSteps />} />
        <Route path="/weeklyroutines" element={<WeeklyRoutines />} />

        <Route path="/HtmlEditor" element={<HtmlEditor />} />

        <Route path="/bookingtransactions" element={<BookingTransaction />} />
      </Routes>
    </>
  );
};

export default PageInnerRoutes;


