
import {
    Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Paper, Button, Modal, TextField, Checkbox, FormControl,
    InputLabel, Select, MenuItem, Grid,
    IconButton,
    CircularProgress,
    Divider
} from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Context } from '../../../Context/Context';
import apiUrl from '../../../Config';
import Alternate from '../../../Assets/Alternate.png'
import { CloseOutlined } from '@mui/icons-material';
import HtmlEditor from '../../../Common/HtmlEditor';
import SuccessPopup from '../../../Common/SuccessPopup';

const DisplayMiniServices = () => {
    const { token, description, setDescription, successPopup,
        succesMessage,
        setSuccessPopup,
        closePopup,
        setSuccesMessage } = useContext(Context)
    const { state } = useLocation();
    const navigate = useNavigate();
    const { subServiceId } = state || {};
    const [miniServices, setMiniServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        id: 0,
        serviceId: subServiceId || '',
        name: '',
        description: '',
        basePrice: '',
        expectedHours: '',
        serviceClassificationType: 'MiniService',
        imageFile: {
            id: 0,
            fileDetails: null,
            fileType: '',
        },
        keyIngredients: '',
        benefits: '',
        bestFor: '',
        description2: '',
        offerPrice: '',
        isActive: false,
        isDeleted: false,
    });
    const [imagePreview, setImagePreview] = useState(null);
    const description2Ref = useRef(null);


    const fetchMiniService = async () => {
        if (subServiceId) {
            try {
                const response = await axios.get(apiUrl + `/servicetypes/getminiservices?subServiceId=${subServiceId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                });
                if (description2Ref.current && description2Ref.current.innerHTML !== formData.description2) {
                    description2Ref.current.innerHTML = formData.description2;
                }
                setMiniServices(response.data || []);
            } catch (error) {
                console.error('Error fetching mini services:', error);
                setMiniServices([]);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleFormChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData((prev) => ({ ...prev, [name]: checked }));
        } else if (name.startsWith('imageFile')) {
            const field = name.split('.')[1];
            setFormData((prev) => ({
                ...prev,
                imageFile: {
                    ...prev.imageFile,
                    [field]: field === 'fileDetails' ? e.target.files[0] : value,
                },
            }));
            if (field === 'fileDetails' && e.target.files[0]) {
                setImagePreview(URL.createObjectURL(e.target.files[0]));
            }
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('id', formData.id);
            formDataToSend.append('serviceId', formData.serviceId);
            formDataToSend.append('name', formData.name);
            formDataToSend.append('description', formData.description);
            formDataToSend.append('basePrice', formData.basePrice);
            formDataToSend.append('expectedHours', formData.expectedHours);
            formDataToSend.append('serviceClassificationType', "MiniService");
            formDataToSend.append('imageFile.id', formData.imageFile.id);
            formDataToSend.append('imageFile.fileDetails', formData.imageFile.fileDetails);
            formDataToSend.append('imageFile.fileType', "Image");
            formDataToSend.append('keyIngredients', formData.keyIngredients);
            formDataToSend.append('benefits', formData.benefits)
            formDataToSend.append('bestFor', formData.bestFor)
            formDataToSend.append('description2', formData.description2);
            formDataToSend.append('offerPrice', formData.offerPrice);
            formDataToSend.append('isActive', formData.isActive);
            formDataToSend.append('isDeleted', formData.isDeleted);

            const apiEndpoint = formData.id
                ? apiUrl + '/servicetypes/updateminiservicetype'
                : apiUrl + '/servicetypes/createminiservicetype';

            const response = await axios({
                method: formData.id ? 'put' : 'post',
                url: apiEndpoint,
                data: formDataToSend,
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });

            if (response?.data?.status === true) {
                setSuccesMessage(response?.data?.message)
                setSuccessPopup(true)
                fetchMiniService();
                setOpen(false);
            }

            resetForm()
            setImagePreview(null);
        } catch (error) {
            console.error('Error submitting mini service:', error);
        }
    };

    const handleEditClick = (miniService) => {
        console.log(miniService, "MiniService")
        setFormData({
            id: miniService.id || 0,
            serviceId: miniService.subServiceId,
            name: miniService.name,
            description: miniService.description,
            basePrice: miniService.basePrice,
            expectedHours: miniService.expectedHours,
            serviceClassificationType: miniService.serviceClassificationType,
            imageFile: {
                id: miniService?.imageFile?.id || 0,
                fileDetails: miniService?.imageFile?.fileDetails,
                fileType: miniService?.imagePath,
            },
            keyIngredients: miniService.keyIngredients,
            benefits: miniService.benefits,
            bestFor: miniService.bestFor,
            description2: miniService.description2,
            offerPrice: miniService.offerPrice,
            isActive: miniService.isActive,
            isDeleted: miniService.isDeleted,
        });
        setImagePreview(apiUrl + "/" + miniService.imagePath)
        setOpen(true);
    };

    const resetForm = () => {
        setFormData({
            id: 0,
            serviceId: subServiceId || '',
            name: '',
            description: '',
            basePrice: '',
            expectedHours: '',
            serviceClassificationType: '',
            imageFile: {
                id: 0,
                fileDetails: null,
                fileType: '',
            },
            keyIngredients: '',
            benefits: '',
            bestFor: '',
            description2: '',
            offerPrice: '',
            isActive: false,
            isDeleted: false,
        });
        setImagePreview("")
    }

    const handleRowClick = (miniService) => {
        navigate(`/serviceSteps`, { state: { miniServiceId: miniService.id } });
    };

    useEffect(() => {
        fetchMiniService();
    }, [subServiceId]);

    return (
        <Box mt={8}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h4" fontWeight={600}>Mini Services</Typography>
                <Button variant="contained" onClick={() => { setOpen(true); resetForm(); }}>
                    Add Mini Service
                </Button>
            </Box>

            <Divider sx={{ mb: 3 }} />

            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100px" mt={2}>
                    <CircularProgress />
                    
                </Box>
            ) : miniServices.length ? (
                <TableContainer component={Paper} sx={{  overflow: 'scroll' }}>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{width:''}}>Image</TableCell>
                                <TableCell sx={{width:'100px'}}>Mini Service Name</TableCell>
                                <TableCell sx={{width:'100px'}}>Description</TableCell>
                                <TableCell sx={{width:'100px'}}>Description 2</TableCell>
                                <TableCell sx={{width:'80px'}}>Base Price</TableCell>
                                <TableCell sx={{width:'80px'}}>Offer Price</TableCell>
                                <TableCell sx={{width:''}}>Expected Hours</TableCell>
                                <TableCell sx={{width:''}}>Key Ingredients</TableCell>
                                <TableCell sx={{width:''}}>Benefits</TableCell>
                                <TableCell sx={{width:''}}>Best For</TableCell>
                                <TableCell sx={{width:''}}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {miniServices?.map((miniService) => (
                                <TableRow key={miniService?.id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                                    <TableCell>
                                        <img
                                            src={apiUrl + "/" + miniService?.imagePath || Alternate}
                                            width={40}
                                            height={40}
                                            style={{ objectFit: 'cover', borderRadius: '8px' }}
                                        />
                                    </TableCell>
                                    <TableCell sx={{width:'100px'}}>{miniService?.name}</TableCell>
                                    <TableCell sx={{width:'100px'}}>{miniService?.description}</TableCell>
                                    <TableCell sx={{width:'100px'}} dangerouslySetInnerHTML={{ __html: miniService?.description2 }} />
                                    <TableCell sx={{width:'100px'}}>{miniService?.basePrice}</TableCell>
                                    <TableCell>{miniService?.offerPrice}</TableCell>
                                    <TableCell>{miniService?.expectedHours}</TableCell>
                                    <TableCell>{miniService?.keyIngredients}</TableCell>
                                    <TableCell>{miniService?.benefits}</TableCell>
                                    <TableCell>{miniService?.bestFor}</TableCell>

                                    <TableCell>
                                        <Box display="flex" justifyContent="space-between">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleEditClick(miniService)}
                                                sx={{ marginRight: 1 }}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleRowClick(miniService)}
                                            >
                                                Steps
                                            </Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>



            ) : (
                <Box display="flex" justifyContent="center" alignItems="center" height="100px" mt={2}>
                    <Typography>No mini-services available</Typography>
                </Box>
            )}

            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="add-mini-service-modal"
                aria-describedby="modal-for-adding-mini-service"
            >
                <Grid container justifyContent='center' alignItems='center' height='100vh'>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%', left: '50%',
                            transform: 'translate(-50%, -50%)',
                            maxWidth: 600, width: '90%',
                            bgcolor: 'background.paper',
                            boxShadow: 24, borderRadius: 2,
                            p: 2,

                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                            <Typography id="add-mini-service-modal" variant="h6" fontWeight="bold" mb={0}>
                                Add Mini Service
                            </Typography>
                            <IconButton onClick={() => setOpen(false)}>
                                <CloseOutlined />
                            </IconButton>
                        </Box>
                        <Box component='form' onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        name="name"
                                        size="small"
                                        value={formData.name}
                                        onChange={handleFormChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        label="Description"
                                        name="description"
                                        size="small"
                                        value={formData.description}
                                        onChange={handleFormChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" color="initial">
                                        Description 2
                                    </Typography>
                                    <HtmlEditor
                                        value={formData.description2}
                                        required
                                        onChange={(newContent) =>
                                            setFormData((prev) => ({ ...prev, description2: newContent }))
                                        }
                                        placeholder="Enter your description here..."
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        label="Base Price"
                                        name="basePrice"
                                        type="number"
                                        size="small"
                                        value={formData.basePrice}
                                        onChange={handleFormChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        label="Expected Hours"
                                        name="expectedHours"
                                        type="number"
                                        size="small"
                                        value={formData.expectedHours}
                                        onChange={handleFormChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        label="Key Ingredients"
                                        name="keyIngredients"
                                        type="text"
                                        size="small"
                                        value={formData.keyIngredients}
                                        onChange={handleFormChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        label="Benefits"
                                        name="benefits"
                                        type="text"
                                        size="small"
                                        value={formData.benefits}
                                        onChange={handleFormChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        label="Best For"
                                        name="bestFor"
                                        type="text"
                                        size="small"
                                        value={formData.bestFor}
                                        onChange={handleFormChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        fullWidth
                                        label="Offer Price"
                                        name="offerPrice"
                                        type="text"
                                        size="small"
                                        value={formData.offerPrice}
                                        onChange={handleFormChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Checkbox
                                        name="isActive"
                                        checked={formData.isActive}
                                        onChange={handleFormChange}
                                    />
                                    Is Active
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Checkbox
                                        name="isDeleted"
                                        checked={formData.isDeleted}
                                        onChange={handleFormChange}
                                    />
                                    Is Deleted
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        required={!imagePreview}
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file) {
                                                setFormData((prev) => ({
                                                    ...prev,
                                                    imageFile: { ...prev.imageFile, fileDetails: file },
                                                }));
                                                const reader = new FileReader();
                                                reader.onload = () => {
                                                    setImagePreview(reader.result);
                                                };
                                                reader.readAsDataURL(file);
                                            }
                                        }}
                                    />
                                </Grid>
                                {imagePreview && (
                                    <Grid item xs={12}>
                                        <img src={imagePreview} alt="Preview" width="60px" />
                                    </Grid>
                                )}
                            </Grid>

                            <Box mt={2} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                                <Button onClick={() => setOpen(false)} color="error" variant="contained">
                                    Cancel
                                </Button>
                                <Button variant="contained" type='submit'>
                                    Submit
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Modal>

            {succesMessage ?
                <SuccessPopup
                    open={successPopup}
                    onClose={closePopup}
                    message={succesMessage}
                /> : ""}
        </Box>
    );
};

export default DisplayMiniServices;


