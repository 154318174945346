
import React, { useContext, useEffect, useState } from 'react';
import { TextField, Switch, FormControlLabel, Grid, MenuItem, InputLabel, Select, FormControl, Button, Typography, Box, Modal, Checkbox } from '@mui/material';
import axios from 'axios';
import apiUrl from '../../Config';
import { Context } from '../../Context/Context';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import SuccessPopup from '../../Common/SuccessPopup';

const WeeklyRoutines = () => {
    const { token, successPopup,
        succesMessage,
        setSuccessPopup,
        closePopup,
        setSuccesMessage, } = useContext(Context);
    const [weeklyRoutine, setWeeklyRoutine] = useState([]);
    const [errorMessages, setErrorMessages] = useState("")
    const location = useLocation();
    const UserId = location?.state?.id;

    const addWeeklyRoutine = () => {
        setWeeklyRoutine((prev) => [
            ...(Array.isArray(prev) ? prev : []),
            {
                id: "",
                dayOfWeek: "",
                startHours: "",
                startMinutes: "",
                endHours: "",
                endMinutes: "",
                isActive: false,
                isDeleted: false,
                perDayJobLimit: ""
            }
        ]);
    };
    const navigate = useNavigate()
    const handleWeeklyRoutineChange = (index, event) => {
        const { name, value, type, checked } = event.target;
        const newWeeklyRoutine = [...weeklyRoutine];
        if (name === "startTime" || name === "endTime") {
            const [hours, minutes] = value.split(":");
            const prefix = name === "startTime" ? "start" : "end";
            newWeeklyRoutine[index][`${prefix}Hours`] = hours;
            newWeeklyRoutine[index][`${prefix}Minutes`] = minutes;
        } else {
            if (type === 'checkbox') {
                newWeeklyRoutine[index][name] = checked;
            } else {
                newWeeklyRoutine[index][name] = value;
            }
        }

        setWeeklyRoutine(newWeeklyRoutine);
        setErrorMessages("")
    };

    const removeWeeklyRoutine = (index) => {
        const newWeeklyRoutine = [...weeklyRoutine];
        newWeeklyRoutine.splice(index, 1);
        setWeeklyRoutine(newWeeklyRoutine);
    };

    const getUserById = async (id) => {
        try {
            const response = await axios.get(`${apiUrl}/users/${id}`);
            if (response?.data?.status === true) {

                setWeeklyRoutine(response?.data?.userWeeklyRoutine);
            }

        } catch (error) {
            if (error?.response?.data?.status === 401) {
                setErrorMessages(error?.response?.data?.title)
            } else {
                setErrorMessages(error?.response?.data?.message || "Something went wrong");
            }
        }
    };




    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const transformedData = weeklyRoutine.map((weeklyRoutine) => ({
                id: Number(weeklyRoutine?.id),
                perDayJobLimit: Number(weeklyRoutine?.perDayJobLimit),
                dayOfWeek: weeklyRoutine?.dayOfWeek,
                startHours: Number(weeklyRoutine?.startHours),
                startMinutes: Number(weeklyRoutine?.startMinutes),
                endHours: Number(weeklyRoutine?.endHours),
                endMinutes: Number(weeklyRoutine?.endMinutes),
                isActive: weeklyRoutine?.isActive,
                isDeleted: weeklyRoutine?.isDeleted,
            }));

            const response = await axios.post(apiUrl + `/worker/updateuserweeklyroutine?userId=${UserId}`, transformedData, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response?.data?.status === true) {
                setSuccesMessage(response?.data?.message)
                setSuccessPopup(true)
            }

        } catch (error) {
            if (error?.response?.data?.status === 401) {
                setSuccesMessage(error?.response?.data?.title)
                setSuccessPopup(true)
            } else {
                setErrorMessages(error?.response?.data?.message || "Something went wrong");
            }
        }
    };

    useEffect(() => {
        getUserById(UserId)
    }, [])

    return (
        <>
            <Box>
                <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Box component="form" onSubmit={handleSubmit}>
                        <Box

                        >
                            <Typography
                                variant="body1"
                                sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                                onClick={() => navigate(-1)}
                            >
                                <ArrowLeft size={15} />
                                Back
                            </Typography>

                            <Typography variant="h5" textAlign="center" gutterBottom>
                                Job Details Form
                            </Typography>


                            {weeklyRoutine.length > 0 ? (
                                weeklyRoutine.map((weeklyRoutine, index) => (
                                    <Grid item xs={12} key={`address-${index}`}>
                                        <Box
                                            sx={{
                                                border: "1px solid #ccc",
                                                padding: 2,
                                                marginBottom: 2,
                                                borderRadius: "8px",
                                            }}
                                        >
                                            <Grid item xs={12}>
                                                <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2, }}
                                                >
                                                    <Typography
                                                        variant="h6" sx={{ display: "flex", alignItems: "center" }}  > {`Day ${index + 1}`}
                                                    </Typography>
                                                    <Box
                                                        sx={{ display: "flex", justifyContent: "end", gap: "10px" }}>
                                                        {index >= 0 && (
                                                            <Button
                                                                variant="contained"
                                                                color="error"
                                                                onClick={() => removeWeeklyRoutine(index)}
                                                            >
                                                                Remove
                                                            </Button>
                                                        )}
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={addWeeklyRoutine}
                                                        >
                                                            Add
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Grid>

                                            <Grid container spacing={2}>

                                                {/* <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="Per Day Job Limit"
                                                        type="number"
                                                        name="perDayJobLimit"
                                                        value={weeklyRoutine.perDayJobLimit}
                                                        onChange={(e) => handleWeeklyRoutineChange(index, e)}
                                                        fullWidth
                                                        size="small"
                                                        required
                                                    />
                                                </Grid> */}

                                                <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth size='small'>
                                                        <InputLabel>Day of Week</InputLabel>
                                                        <Select
                                                            label="Day of Week"
                                                            name="dayOfWeek"
                                                            value={weeklyRoutine.dayOfWeek}
                                                            onChange={(e) => handleWeeklyRoutineChange(index, e)}
                                                            size="small"
                                                            required
                                                        > 
                                                            {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => (
                                                                <MenuItem key={day} value={index}>
                                                                    {day}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                {/* <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="Start Time"
                                                        type="time"
                                                        name="startTime"
                                                        InputLabelProps={{ shrink: true }}
                                                        value={
                                                            weeklyRoutine.startHours !== undefined && weeklyRoutine.startMinutes !== undefined
                                                                ? `${String(weeklyRoutine.startHours).padStart(2, "0")}:${String(weeklyRoutine.startMinutes).padStart(2, "0")}`
                                                                : ""
                                                        }
                                                        onChange={(e) => handleWeeklyRoutineChange(index, e)}
                                                        fullWidth
                                                        size="small"
                                                        required
                                                    />
                                                </Grid> */}
                                                
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="End Time"
                                                        type="time"
                                                        name="endTime"
                                                        InputLabelProps={{ shrink: true }}
                                                        value={
                                                            weeklyRoutine.endHours !== undefined && weeklyRoutine.endMinutes !== undefined
                                                                ? `${String(weeklyRoutine.endHours).padStart(2, "0")}:${String(weeklyRoutine.endMinutes).padStart(2, "0")}`
                                                                : ""
                                                        }
                                                        onChange={(e) => handleWeeklyRoutineChange(index, e)}
                                                        fullWidth
                                                        size="small"
                                                        required
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                name="isActive"
                                                                checked={weeklyRoutine.isActive}
                                                                onChange={(e) => handleWeeklyRoutineChange(index, e)}
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Is Active"
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                name="isDeleted"
                                                                checked={weeklyRoutine.isDeleted}
                                                                onChange={(e) => handleWeeklyRoutineChange(index, e)}
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Is Deleted"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                ))
                            ) : (
                                <>
                                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: "end" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={addWeeklyRoutine}
                                        >
                                            Add Weekly outeins
                                        </Button>
                                    </Grid>
                                    <Box>
                                        <Typography variant='body2'>    No weekly routines added. Please click "Add Weekly Routines" to create one. </Typography>
                                    </Box>
                                </>
                            )}

                            {errorMessages && (<Typography variant='body' color='error'>{errorMessages}</Typography>)}

                            <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                                {weeklyRoutine.length > 0 && (
                                    <Button variant="contained" color="primary" type="submit">
                                        Submit
                                    </Button>
                                )}
                            </Box>

                        </Box>

                    </Box>
                </Grid>
            </Box>

            {succesMessage ?
                <SuccessPopup
                    open={successPopup}
                    onClose={closePopup}
                    message={succesMessage}
                /> : ""}
        </>
    );
};
export default WeeklyRoutines;