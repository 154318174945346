import { Box, Divider, Typography } from '@mui/material'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import apiUrl from '../../Config'
import { useNavigate, useParams } from 'react-router-dom'
import { Context } from '../../Context/Context'
import { ArrowLeft } from 'lucide-react'
import { green } from '@mui/material/colors'

const BookingDetails = () => {
    const [bookingDetails, setBookingDetails] = useState([])
    const { id } = useParams()
    const { token, states, serviceTypes } = useContext(Context)
    const navigate = useNavigate()

    const [stateName, setStateName] = useState("")
    const [errors, setErrors] = useState("")


    const getBookingDetails = async () => {
        try {
            const response = await axios.get(apiUrl + `/booking/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
            )
            setBookingDetails(response?.data)
        } catch (error) {
            if (error?.response?.data?.status === 401) {
                setErrors(error?.response?.data?.title)
            } else {
                setErrors(error?.response?.data?.message || "Something went wrong");
            }
        }
    }

    const gender = [
        { id: 0, name: "Male" },
        { id: 1, name: "Female" },
        { id: 2, name: "Other" },
    ]

    const getGender = gender.find((item) => item.id === bookingDetails?.workerDetails?.gender);

    const serviceTypesName = serviceTypes.find((item) => item.id === bookingDetails?.bookingDetail?.serviceId)

    useEffect(() => {
        if (states.length > 0 && bookingDetails?.bookingDetail?.serviceState) {
            const matchedState = states.find(
                (state) => state.id === Number(bookingDetails?.bookingDetail?.serviceState)
            );
            setStateName(matchedState ? matchedState?.stateName : '');
        }
    }, [bookingDetails, states]);


    useEffect(() => {
        getBookingDetails()
    }, [])

    return (
        <Box mt={8}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', mb: '20px' }}>
                <ArrowLeft onClick={() => navigate(-1)} cursor={'pointer'}/>
                <Typography variant="h4" color="initial" fontWeight={600}>Booking Details</Typography>
            </Box>
            <Box>
                <Typography variant='h5' textAlign={'center'} color='error' mb={2}>{errors}</Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row' }, justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ border: '1px solid lightgrey', height: 'auto', width: { xs: '100%', sm: '95%', md: '95%', lg: '47%' }, padding: '10px', borderRadius: '5px' }}>
                    <Typography variant="h5" color="initial">Booking Details</Typography>
                    <Divider sx={{ borderColor: 'lightgrey', my: 1 }} />

                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 2fr', rowGap: 1.5, columnGap: 3 }}>
                        <Typography variant="h6" color="initial">Booking ID:</Typography>
                        <Typography variant="h6" color="initial">{bookingDetails?.bookingDetail?.bookingID || 'N/A'}</Typography>

                        <Typography variant="h6" color="initial">Booking Created Date:</Typography>
                        <Typography variant="h6" color="initial">{new Date(bookingDetails?.bookingDetail?.createDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) || 'N/A'}</Typography>

                        <Typography variant="h6" color="initial">Booking Status:</Typography>
                        <Typography variant="h6" color="initial">{bookingDetails?.bookingDetail?.bookingStatus || 'N/A'}</Typography>

                        <Typography variant="h6" color="initial">Service ID:</Typography>
                        <Typography variant="h6" color="initial">{serviceTypesName?.name || 'N/A'}</Typography>

                        <Typography variant="h6" color="initial">Sub Service ID:</Typography>
                        <Typography variant="h6" color="initial">{bookingDetails?.bookingDetail?.subServiceId || 'N/A'}</Typography>

                        <Typography variant="h6" color="initial">Service Address:</Typography>
                        <Typography variant="h6" color="initial">
                            {`${bookingDetails?.bookingDetail?.serviceAddress1 || ''}, ${bookingDetails?.bookingDetail?.serviceAddress2 || ''},
                            ${bookingDetails?.bookingDetail?.serviceCity || ''},${bookingDetails?.bookingDetail?.serviceLandMark || ''}
                            ${bookingDetails?.bookingDetail?.servicePincode || ''},${stateName}`.trim() || 'N/A'}
                        </Typography>
                    </Box>

                </Box>
                <Box sx={{ border: '1px solid lightgrey', height: 'auto', width: { xs: '100%', sm: '95%', md: '95%', lg: '47%' }, padding: '10px', borderRadius: '5px' }}>
                    <Typography variant="h5" color="initial" mb={1}>Professional Details</Typography>
                    <Divider sx={{ borderColor: 'lightgrey', my: 1 }} />
                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 2fr', rowGap: 1.5, columnGap: 3 }}>

                        <Typography variant="h6" color="initial">Professional Name:</Typography>
                        <Typography variant="h6" color="initial">
                            {`${bookingDetails?.workerDetails?.firstName || ''} ${bookingDetails?.workerDetails?.lastName || ''}`.trim() || 'N/A'}
                        </Typography>

                        <Typography variant="h6" color="initial">Gender</Typography>
                        <Typography variant="h6" color="initial">{getGender?.name || 'N/A'}</Typography>

                        <Typography variant="h6" color="initial">Email</Typography>
                        <Typography variant="h6" color="initial">{bookingDetails?.workerDetails?.email || 'N/A'}</Typography>

                        <Typography variant="h6" color="initial">Phone Number</Typography>
                        <Typography variant="h6" color="initial">{bookingDetails?.workerDetails?.phoneNumber || 'N/A'}</Typography>

                        <Typography variant="h6" color="initial">Birth Date</Typography>
                        <Typography variant="h6" color="initial">{new Date(bookingDetails?.workerDetails?.birthDate).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</Typography>

                        <Typography variant="h6" color="initial">Preferred Language</Typography>
                        <Typography variant="h6" color="initial">{bookingDetails?.workerDetails?.prefferredLanguage || 'N/A'}</Typography>

                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default BookingDetails