
import React, { useContext, useEffect, useState } from "react";
import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { RotateCcw } from "lucide-react";
import axios from "axios";
import apiUrl from "../../Config";
import { Context } from "../../Context/Context";

const BookingTransaction = () => {
    const { token } = useContext(Context)
    const [bookingTransactionData, setBookingTransactionData] = useState([])
    const [filterData, setFilterData] = useState({
        bookingId: "",
        transactionId: "",
        transactionStatus: "",
        transactionStatusMessage: "",
        transactionMode: "",
        paymentGatewayTransactionId: "",
        fromDate: "",
        toDate: "",
    });

    const [selectedFields, setSelectedFields] = useState([]);
    // const [searchInput, setSearchInput] = useState("");
    const [filterOpen, setFilterOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const [error, setError] = useState("")

    // Handle changes in form inputs
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilterData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleFieldChange = (event) => {
        const { value } = event.target;
        setFilterOpen(false);
        const removedFields = selectedFields.filter((field) => !value.includes(field));
        removedFields.forEach((field) => {
            if (field in filterData) {
                setFilterData((prevState) => ({
                    ...prevState,
                    [field]: "",
                }));
            }
        });
        setSelectedFields(value);
    };

    const fieldOptions = [
        { label: "bookingId", value: "bookingId" },
        { label: "transactionId", value: "transactionId" },
        { label: "Transaction Status", value: "status" },
        { label: "Transaction Status Message", value: "message" },
        { label: "Transaction Mode", value: "transactionMode" },
        { label: "PaymentGateway TransactionId", value: "paymentGatewayTransactionId" },
        { label: "From Date", value: "fromDate" },
        { label: "To Date", value: "toDate" },
    ];

    const handleClearFields = () => {

        setSelectedFields([]);
        setFilterData({
            bookingId: "",
            transactionId: "",
            transactionStatus: "",
            transactionStatusMessage: "",
            transactionMode: "",
            paymentGatewayTransactionId: "",
            fromDate: "",
            toDate: "",
        });
        handleSearchDetails(false)
        // handleSearch()
    };

    const statusMapping = {
        1: "Started",
        2: "Success",
        3: "Cancelled",
    };


    const handleNextPage = () => {
        if (hasMore) {
            setPageNumber(pageNumber + 1);
        }
    }

    const handlePreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    }
   
    const handleSearchDetails = (seletedFieldsData=true) => {
        handleSearch(seletedFieldsData);
        setPageNumber(1)
    }

    const handleSearch = async (seletedFieldsData=true) => {
        setLoading(true);
        const requestData = {
            pageSize: 5, 
            pageNumber: pageNumber,
            ...(selectedFields.includes("status") && seletedFieldsData&&{ transactionStatus: filterData.transactionStatus }),
            ...(selectedFields.includes("transactionMode") && seletedFieldsData&&{ transactionMode: filterData.transactionMode }),
            ...(selectedFields.includes("bookingId") && seletedFieldsData&&{ bookingId: filterData.bookingId }),
            ...(selectedFields.includes("transactionId") && seletedFieldsData&&{ transactionId: filterData.transactionId }),
            ...(selectedFields.includes("paymentGatewayTransactionId") && seletedFieldsData&&{ paymentGatewayTransactionId: filterData.paymentGatewayTransactionId }),
            ...(selectedFields.includes("transactionStatusMessage") && seletedFieldsData&&{ transactionStatusMessage: filterData.transactionStatusMessage }),
            ...(selectedFields.includes("fromDate") && seletedFieldsData&&{ fromDate: filterData.fromDate }),
            ...(selectedFields.includes("toDate") && seletedFieldsData&&{ toDate: filterData.toDate }),
        };
        try {
            const response = await axios.post(
                apiUrl + "/api/transaction/searchtransactions",
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json-patch+json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setHasMore(response?.data?.response?.transactionRecords?.length === 5);
            if (response?.data?.status === true) {
                setBookingTransactionData(response?.data?.response?.transactionRecords)
            }

        } catch (error) {
            setError("something went be wrong:", error);

        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleSearch()
    }, [pageNumber])

    // const getTransactonDetailsById = async () => {
    //     try {
    //         const response = await axios.get(apiUrl + `/api/transaction/gettransactionbyid?transactionId=${"E8A2EF70-AE1E-4CB1-27FD-08DD1F4F9E39"}`,
    //             {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             }
    //         )
    //         if (response?.data?.status === true) {
    //             console.log(response?.data?.transaction, "transactions");
    //         }
    //     } catch (error) {
    //         setError("something went to be")
    //     }
    // }

    return (
        <Box>
            <Grid sx={{ display: "flex", mt: "20px", gap: "10px" }}>
                <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                        <FormControl variant="outlined" size="small" fullWidth>
                            <InputLabel>Select Filters</InputLabel>
                            <Select
                                multiple
                                value={selectedFields}
                                onChange={handleFieldChange}
                                label="Select Filters"
                                open={filterOpen}
                                onOpen={() => setFilterOpen(true)}
                                onClose={() => setFilterOpen(false)}
                                renderValue={(selected) => selected.join(", ")}
                            >
                                {fieldOptions.map((field) => (
                                    <MenuItem key={field.value} value={field.value}>
                                        {field.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    {selectedFields.includes("bookingId") && (
                        <Grid item xs={12} sm={6} md={2}>
                            <TextField
                                label="Booking Id"
                                variant="outlined"
                                size="small"
                                fullWidth
                                type="number"
                                name="bookingId"
                                value={filterData.bookingId}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    )}
                    {selectedFields.includes("transactionId") && (
                        <Grid item xs={12} sm={6} md={2}>
                            <TextField
                                label="Transaction Id"
                                variant="outlined"
                                size="small"
                                fullWidth
                                name="transactionId"
                                value={filterData.transactionId}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    )}

                    {selectedFields.includes("status") && (
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel>Transaction Status</InputLabel>
                                <Select
                                    value={filterData.transactionStatus}
                                    onChange={handleInputChange}
                                    label="Transaction Status"
                                    name="transactionStatus"
                                >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    <MenuItem value="1">Started</MenuItem>
                                    <MenuItem value="2">Success</MenuItem>
                                    <MenuItem value="3">Cancelled</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                    )}

                    {selectedFields.includes("message") && (
                        <Grid item xs={12} sm={6} md={2}>
                            <TextField
                                label="Transaction Status Message"
                                variant="outlined"
                                size="small"
                                fullWidth
                                name="transactionStatusMessage"
                                value={filterData.transactionStatusMessage}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    )}

                    {selectedFields.includes("transactionMode") && (
                        <Grid item xs={12} sm={6} md={2}>
                            <TextField
                                label="Transaction Mode"
                                variant="outlined"
                                size="small"
                                fullWidth
                                name="transactionMode"
                                value={filterData.transactionMode}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    )}

                    {selectedFields.includes("paymentGatewayTransactionId") && (
                        <Grid item xs={12} sm={6} md={2}>
                            <TextField
                                label="Payment Gateway Transaction Id"
                                variant="outlined"
                                size="small"
                                fullWidth
                                name="paymentGatewayTransactionId"
                                value={filterData.paymentGatewayTransactionId}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    )}

                    {selectedFields.includes("fromDate") && (
                        <Grid item xs={12} sm={6} md={2}>
                            <TextField
                                label="From Date"
                                type="datetime-local"
                                variant="outlined"
                                size="small"
                                fullWidth
                                name="fromDate"
                                value={filterData.fromDate}
                                onChange={handleInputChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    )}

                    {selectedFields.includes("toDate") && (
                        <Grid item xs={12} sm={6} md={2}>
                            <TextField
                                label="To Date"
                                type="datetime-local"
                                variant="outlined"
                                size="small"
                                fullWidth
                                name="toDate"
                                value={filterData.toDate}
                                onChange={handleInputChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    )}

                    {/* Select which fields to display */}
                   
                </Grid>
                <Grid item xs={12} sm={6} md={2} sx={{ textAlign: "right", mb: "10px", }}>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                        <Button onClick={handleSearchDetails} variant="contained">
                            Search
                        </Button>
                        {selectedFields.length > 0 && (
                            <Grid item xs={12} sm={6} md={2}>
                                <Button variant="contained" onClick={handleClearFields} color="#e13737">
                                    <RotateCcw color="#e13737" />
                                </Button>
                            </Grid>
                        )}
                    </Box>
                </Grid>
            </Grid>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100px" mt={2}>
                    <CircularProgress />
                </Box>
            ) : (
                <TableContainer component={Paper} sx={{ mt: "15px" }}>
                    {bookingTransactionData?.length > 0 ? (
                        <>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>First Name</TableCell>
                                        <TableCell>Last Name</TableCell>
                                        <TableCell>Transaction ID</TableCell>
                                        <TableCell>Transaction Type</TableCell>
                                        <TableCell>Transaction Amount</TableCell>
                                        {/* <TableCell>Transaction Mode</TableCell> */}
                                        <TableCell>Status</TableCell>
                                        <TableCell align="right">Created On</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {bookingTransactionData?.map((record, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{record?.firstName}</TableCell>
                                            <TableCell>{record?.lastName}</TableCell>
                                            <TableCell>{record?.transaction?.id}</TableCell>
                                            {/* <TableCell>{record?.transaction?.transactionModeCode}</TableCell> */}
                                         
                                            <TableCell>{record?.transaction?.transactionType === 1 ? "Booking Services" : "MemberShip"}</TableCell>
                                            <TableCell>{record?.transaction?.transactionAmount}</TableCell>
                                            <TableCell>
                                                {statusMapping[record?.transaction?.status] || ""}
                                            </TableCell>
                                            <TableCell align="right">{new Date(record?.transaction?.createdOn).toLocaleString()}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </>
                    ) : (
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography variant="h6" sx={{ mt: 2, textAlign: "center" }}>
                                No data found. Please select the filters to refine your search.
                            </Typography>
                        </Box>
                    )}
                </TableContainer>
            )}
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <Button onClick={handlePreviousPage} disabled={pageNumber === 1}   variant="outlined" >  Previous </Button>
                <Button onClick={handleNextPage} disabled={!hasMore} variant="outlined" sx={{ ml: 2 }}  >  Next </Button>
            </Box>
        </Box>
    );
};
export default BookingTransaction;
