import React, { useContext } from "react";
import {
    Box,  Typography,Grid,
    Paper,Button, Card,CardContent, IconButton,Link, ToggleButtonGroup,  ToggleButton, Stack, Divider,
} from "@mui/material";
import { Doughnut, Line, Bar } from "react-chartjs-2";
import {
    Chart as ChartJS, ArcElement, Tooltip,  Legend,
    CategoryScale,LinearScale,  LineElement, BarElement, PointElement,
} from "chart.js";
import {  Mail, DollarSign,  Users, CreditCard, Calendar, Upload,} from "lucide-react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Context } from "../../Context/Context";
 
// Register required elements for Chart.js
ChartJS.register(
    ArcElement, Tooltip, Legend, CategoryScale, LinearScale,LineElement, BarElement, PointElement
);
// Data for Charts
const donutData = {
    labels: ["Cooking(Chef)", "Maid", "Cleaning", "Beauty"],
    datasets: [
        {
            data: [1754, 1234, 878, 270],
            backgroundColor: ["#8b5cf6", "#f87171", "#34d399", "#60a5fa"],
            hoverBackgroundColor: ["#6b21a8", "#b91c1c", "#059669", "#2563eb"],
            borderWidth: 0,
        },
    ],
};
 
const lineData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
        {
            label: "Delivered",
            data: [30, 50, 80, 40, 60, 100, 90, 70, 110, 120, 130, 150],
            borderColor: "#3F51B5",
            tension: 0.4,
        },
        {
            label: "Pending",
            data: [20, 30, 40, 20, 40, 50, 30, 20, 50, 60, 50, 40],
            borderColor: "#FF9800",
            borderDash: [5, 5],
            tension: 0.4,
        },
        {
            label: "Cancelled",
            data: [10, 20, 10, 15, 25, 20, 10, 5, 15, 25, 20, 15],
            borderColor: "#E91E63",
            tension: 0.4,
        },
    ],
};
 
const barData = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
        {
            label: "This Week",
            data: [50, 70, 90, 60, 80, 100, 120],
            backgroundColor: "#3F51B5",
        },
        {
            label: "Last Week",
            data: [40, 60, 70, 50, 60, 80, 100],
            backgroundColor: "#FF9800",
        },
    ],
};
 
const categories = [
    {
        name: "Cooking(Chef)",
        color: "#8b5cf6",
        sales: 1754,
        change: 0.64,
        increase: true,
    },
    {
        name: "Maid",
        color: "#f87171",
        sales: 1234,
        change: -2.75,
        increase: false,
    },
    {
        name: "Cleaning",
        color: "#34d399",
        sales: 878,
        change: 1.54,
        increase: true,
    },
    {
        name: "Beautician ",
        color: "#60a5fa",
        sales: 270,
        change: 0.0,
        increase: true,
    },
];
 
// Stats Data
const stats = [
    { title: "Total Bookings", value: "32,981",  },
    { title: "Total Users", value: "$14,32,145",  },
    { title: "Total Professionals", value: "4,678",  },
    { title: "No of Services", value: "$645",  },
];
 
// Dashboard Component
const Dashboard = () => {

   const userName=localStorage.getItem('userName')
    
    return (
        <Box sx={{ p: 3, minHeight: "100vh" }}>
            {/* Header */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
                <Box sx={{ display: "flex", alignItems: "center" ,gap:"10px"}}>
                    <Typography variant="h6">welcome</Typography>
                    <Typography variant="h5" color="textSecondary">
                        {userName}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "8px 12px",
                            border: "1px solid #e0e0e0",
                            borderRadius: 2,
                            backgroundColor: "#fff",
                        }}
                    >
                        <Calendar style={{ marginRight: "8px", color: "gray" }} size={20} />
                        <Typography variant="subtitle2" color="textSecondary">
                            May 01, 2024 to May 30, 2024
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Upload size={20} />}
                        sx={{ textTransform: "none" }}
                    >
                        Export Report
                    </Button>
                </Box>
            </Box>
 
            {/* Stats Section */}
            <Grid container spacing={2}>
                {stats.map((stat, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <Card
                            sx={{
                                borderRadius: 2,
                                border: "1px solid ",
                                borderColor:"rgba(0, 0, 0, 0.12);",
                                boxShadow: "none",
                            }}
                        >
                            <CardContent>
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        {stat.title}
                                    </Typography>
                                    <IconButton
                                        sx={{
                                            backgroundColor: stat.color,
                                            color: "#fff",
                                            width: 35,
                                            height: 35,
                                            "&:hover": { backgroundColor: stat.color },
                                        }}
                                    >
                                        {/* {stat.icon} */}
                                    </IconButton>
                                </Box>
                                <Typography variant="h5" sx={{ mt: 2 }}>
                                    {stat.value}
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        mt: 1,
                                    }}
                                >
                                    <Link
                                        href="#"
                                        underline="hover"
                                        color="primary"
                                        sx={{ fontSize: "0.9rem", fontWeight: 500 }}
                                    >
                                        {/* {stat.link} */}
                                    </Link>
                                   
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
 
            {/* Charts Section */}
            <Grid container spacing={2} sx={{mt:"3px"}}>
                <Grid item xs={12} md={6} >
                    <Paper elevation={3} sx={{ p: 2, boxShadow: 'none', border: '1px solid',   borderColor:"rgba(0, 0, 0, 0.12);", }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
                            Number of Register Users
                        </Typography>
                        <Bar data={barData} options={{ maintainAspectRatio: true }} />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ p: 3, boxShadow: 'none', border: '1px solid ',   borderColor:"rgba(0, 0, 0, 0.12);", }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                                Booking Statistics
                            </Typography>
                            <ToggleButtonGroup size="small" exclusive color="primary" defaultValue="day">
                                <ToggleButton value="day">Day</ToggleButton>
                                <ToggleButton value="week">Week</ToggleButton>
                                <ToggleButton value="month">Month</ToggleButton>
                                <ToggleButton value="year">Year</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        <Line data={lineData} options={{ maintainAspectRatio: true }} />
                    </Paper>
                </Grid>
            </Grid>
 
            {/* Top Selling Categories Section */}
            <Box sx={{ mt: 3 }}>
                <Paper sx={{ p: 3, border: '1px solid #e0e0e0', boxShadow: 'none' }}>
                    <Typography variant="subtitle1" sx={{ mb: 2 }}>
                        Top Booked Services
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
                        <Box width={250} height={250} position="relative">
                            <Doughnut data={donutData} options={{ cutout: "70%", plugins: { legend: { display: false } } }} />
                            <Box
                                position="absolute"
                                top="50%"
                                left="50%"
                                style={{ transform: "translate(-50%, -50%)", textAlign: "center" }}
                            >
                                <Typography variant="h6" fontWeight="bold">
                                    Total Bookings
                                </Typography>
                                <Typography variant="h4" fontWeight="bold">
                                    4592
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Divider sx={{ my: 3 }} />
                    <Stack spacing={1} divider={<Divider flexItem />}>
                        {categories.map((category) => (
                            <Box
                                key={category.name}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{ py: 0.5 }}
                            >
                                <Box display="flex" alignItems="center">
                                    <Box
                                        sx={{
                                            width: 8,
                                            height: 8,
                                            borderRadius: "50%",
                                            backgroundColor: category.color,
                                            mr: 0.5,
                                            mb:2.5
                                        }}
                                    ></Box>
                                    <Box>
                                        <Typography variant="subtitle2" fontWeight="medium">
                                            {category.name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color={category.increase ? "green" : "red"}
                                            display="flex"
                                            alignItems="center"
                                        >
                                            {category.increase ? (
                                                <ArrowUpwardIcon fontSize="small" />
                                            ) : (
                                                <ArrowDownwardIcon fontSize="small" />
                                            )}
                                            {Math.abs(category.change)}%
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography
                                    variant="body2"
                                    fontWeight="bold"
                                    color="textSecondary"
                                    sx={{ mt: 1 }}
                                >
                                    {category.sales} Bookings
                                </Typography>
                            </Box>
                        ))}
                    </Stack>
                </Paper>
            </Box>
 
        </Box>
    );
}; 
 
export default Dashboard;