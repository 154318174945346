
import React, { useContext, useEffect, useState } from 'react';
import {
    Box, Typography, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Button, Modal, Grid, TextField, MenuItem, Checkbox, FormControlLabel, IconButton,
    CircularProgress,
    Divider
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../../Context/Context';
import apiUrl from '../../../Config';
import { CloseOutlined } from '@mui/icons-material';
import SuccessPopup from '../../../Common/SuccessPopup';
import { Pencil } from 'lucide-react';

const DisplayServices = () => {

    const { token, successPopup,
        succesMessage,
        setSuccessPopup,
        closePopup,
        setSuccesMessage } = useContext(Context)

    const navigate = useNavigate();
    const [services, setServices] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [formData, setFormData] = useState({
        id: 0,
        name: '',
        description: '',
        imageFile: {
            id: 0,
            fileDetails: null,
            fileType: '',
            isDeleted: '',
        },
        isActive: false,
        isDeleted: false,
    });
    const [imagePreview, setImagePreview] = useState(null);
    const [loading, setLoading] = useState(false)


    const fetchServices = async () => {
        setLoading(true)
        try {
            const response = await axios.get(apiUrl + '/servicetypes/getservicetypes', {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            });
            setServices(response.data);
        } catch (error) {
            console.error('Error fetching services:', error);
        } finally {
            setLoading(false)
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleImageFileChange = (e) => {
        const file = e.target.files[0];
        setFormData((prev) => ({
            ...prev,
            imageFile: {
                ...prev.imageFile,
                fileDetails: file,
            },
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('id', formData.id);
            formDataToSend.append('name', formData.name);
            formDataToSend.append('description', formData.description);
            formDataToSend.append('imageFile.id', formData.imageFile.id);
            formDataToSend.append('imageFile.fileDetails', formData.imageFile.fileDetails);
            formDataToSend.append('imageFile.fileType', "Image");
            formDataToSend.append('imageFile.isDeleted', formData.imageFile.isDeleted);
            formDataToSend.append('isActive', formData.isActive);
            formDataToSend.append('isDeleted', formData.isDeleted);
            let response;
            if (isEditMode) {
                response = await axios.put(apiUrl + '/servicetypes/updateservicetype', formDataToSend, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                });
            } else {
                response = await axios.post(apiUrl + '/servicetypes/createservicetype', formDataToSend, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                });
            }
            if (response.data.status === true) {
                setSuccesMessage(response.data.message)
                setSuccessPopup(true)
                fetchServices();
                setOpenModal(false);
            }

        } catch (error) {
            console.error('Error in handleSubmit', error);
        }
    };

    const handleEditClick = (service) => {
        setIsEditMode(true);
        setFormData({
            id: service.id || 0,
            name: service.name,
            description: service.description,
            imageFile: {
                id: service.imageFile?.id || 0,
                fileDetails: service.imagePath,
                fileType: service.imageFile?.fileType || '',
                isDeleted: service.imageFile?.isDeleted || '',
            },
            isActive: service.isActive,
            isDeleted: service.isDeleted,
        });
        setOpenModal(true);
        setImagePreview(apiUrl + "/" + service.imagePath);
    };

    const handleRowClick = (service) => {
        navigate(`/displaySubServices`, { state: { service } });
    };

    useEffect(() => {
        fetchServices();
    }, []);

    return (
        <Box mt={8} sx={{ maxWidth: '1200px', margin: 'auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h4" fontWeight={600} textAlign="center">
                    All Services
                </Typography>
                <Button variant="contained" onClick={() => {
                    setIsEditMode(false);
                    setFormData({
                        id: '',
                        name: '',
                        description: '',
                        imageFile: {
                            id: '',
                            fileDetails: null,
                            fileType: '',
                            isDeleted: '',
                        },
                        isActive: false,
                        isDeleted: false,
                    });
                    setOpenModal(true);
                    setImagePreview("")
                }}>
                    Add Service
                </Button>
            </Box>

            <Divider sx={{ mb: 3 }} />

            {
                loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100px" mt={2}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell >Image</TableCell>
                                    <TableCell >Name</TableCell>
                                    <TableCell >Description</TableCell>
                                    <TableCell align='right' >Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {services?.map((service) => (
                                    <>
                                        <TableRow key={service?.id} hover>
                                            <TableCell>
                                                <img
                                                    src={apiUrl + "/" + service?.imagePath}
                                                    alt={service?.name}
                                                    style={{ width: '40px', height: '40px', borderRadius: '5px' }}
                                                />
                                            </TableCell>
                                            <TableCell >{service?.name}</TableCell>
                                            <TableCell >{service?.description}</TableCell>
                                            <TableCell align='right' >
                                                <IconButton onClick={() => handleEditClick(service)} color='primary'>
                                                    <Pencil size={18} />
                                                </IconButton>
                                                <Button variant="contained" onClick={() => handleRowClick(service)}>
                                                    Sub Services
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ))}
                            </TableBody>
                        </Table>

                    </TableContainer>
                )
            }

            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Grid container justifyContent='center' alignItems='center' height='100vh' >
                    <Box
                        sx={{
                            position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper', boxShadow: 24, p: 4, maxWidth: 550, width: '90%', borderRadius: 2,
                        }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6" fontWeight={600} mb={2}>
                                {isEditMode ? 'Edit Service' : 'Add New Service'}
                            </Typography>
                            <IconButton
                                onClick={() => setOpenModal(false)}
                                sx={{
                                    position: 'absolute',
                                    top: '20px',
                                    right: '20px',
                                }}
                            >
                                <CloseOutlined />
                            </IconButton>
                        </Box>
                        <Box component={'form'} onSubmit={handleSubmit}>
                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={6}>
                                    <TextField label="Name" name="name" required fullWidth size='small' value={formData.name} onChange={handleInputChange} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField label="Description" required name="description" fullWidth size='small' value={formData.description} onChange={handleInputChange} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox name="isActive" size='small' checked={formData.isActive} onChange={handleCheckboxChange} />
                                        }
                                        label="Is Active"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox name="isDeleted" size='small' checked={formData.isDeleted} onChange={handleCheckboxChange} />
                                        }
                                        label="Is Deleted"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="body1" mb={1}>Choose Image</Typography>
                                    <input type="file" accept="image/*" required={!imagePreview} onChange={handleImageFileChange} />
                                    {imagePreview && (
                                        <img src={imagePreview} alt="Preview" style={{ width: '100px', marginTop: '10px', borderRadius: '5px' }} />
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" fullWidth sx={{ mt: 2 }} type='submit' >
                                        {isEditMode ? 'Update Service' : 'Add Service'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Modal>

            {succesMessage ?
                <SuccessPopup
                    open={successPopup}
                    onClose={closePopup}
                    message={succesMessage}
                /> : ""}
        </Box>
    );
};
export default DisplayServices;
