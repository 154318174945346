import axios from "axios";
import React, { createContext, useState, useEffect } from "react";
import apiUrl from "../Config";
import { useNavigate } from "react-router-dom";

const Context = createContext();

const UserContext = ({ children }) => {
  const [userProfile, setUserProfile] = useState("");
  const [roles, setRoles] = useState([]);
  const [token, setToken] = useState(() => localStorage.getItem("userToken") || false);
  const [description, setDescription] = useState('');
  const [languages, setLanguages] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [states,setStates]=useState([])
  // const token = localStorage.getItem("userId");
  const [isLoggedIn, setIsLoggedIn] = useState(token);
  const [zones, setZones] = useState([]);
  const [successPopup, setSuccessPopup] = useState(false)
  const [succesMessage, setSuccesMessage] = useState("")
  const [isConfirmDeleteModelOpen, setIsConfirmDeleteModelOpen] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [error, setError] = useState("")
  const navigate = useNavigate()
  const closePopup = () => {
    setSuccessPopup(false);
    setSuccesMessage("")
  };

  const getrefreshToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const response = await axios.post(`${apiUrl}users/refresh-token`, {
        accessToken: token,
        refreshToken: refreshToken,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'Authorization': `Bearer ${token}`
        },
      });

      localStorage.setItem("userToken", response.data.accessToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);
      localStorage.setItem("tokenTimestamp", Date.now());
    } catch (error) {
      setError(error)

    }
  };

  const getUserProfile = async () => {
    if (token) {
      try {
        const response = await axios.get(
          apiUrl + "/users/getuserbytoken",
          {
            headers: {
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response?.data?.status === true) {
          setUserProfile(response?.data);
          localStorage.setItem("userName",response?.data?.userdetails?.firstName);
        } else {
          await getrefreshToken();
          navigate && navigate('/');

          localStorage.removeItem("userToken");
          localStorage.removeItem("refreshToken");
          window.location.reload();
        }
      } catch (error) {
        setError("Error fetching user details:", error);
        setUserProfile(null);
      }
    }
  };

  const getStates = async () => {
    try {
      const response = await axios.get(apiUrl + `/utilities/getstates`);
      if (response.data.status === true) {
        setStates(response?.data?.state);
      }
    } catch (error) {
      setError("Error fetching states:", error);
    }
  };

  const getAllRoles = async () => {
    if (token) {
      try {
        const response = await axios.get(apiUrl + "/rolemanager/getallroles",
          {
            headers: {
              "Accept": "*/*",
              "Authorization": `Bearer ${token}`,
            },
          }
        );

        if (response?.data?.status === true) {
          const sortedRoles = response?.data?.roles.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setRoles(sortedRoles);
        }
      } catch (error) {
        setError(error, "failed to fetch roles");
      }
    }
  };

  const getlanguages = async () => {
    try {
      const response = await axios.get(`${apiUrl}/utilities/getlanguages`);
      if (response?.data?.status === true) {
        setLanguages(response?.data?.languages);
      }
    } catch (error) {
      setError("Error fetching data:", error);
    }
  };
  const getservicetypes = async () => {
    try {
      const serviceResponse = await axios.get(
        `${apiUrl}/servicetypes/getservicetypes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
      );
      setServiceTypes(serviceResponse.data);
    } catch (error) {
      setError("Error fetching data:", error);
    }
  };
  const getZones = async () => {
    try {
      const response = await axios.get(apiUrl + "/zone/listzones", {
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.status === true) {
        setZones(response?.data?.zone);
      }
    } catch (error) {
      setError("Error fetching zones:", error);
    }
  };



  useEffect(() => {
    getUserProfile();
    if (token) {

      getAllRoles();
      getlanguages();
      getservicetypes();
      getZones();
      getStates()
    }
    // getrefreshToken()
  }, [token]);



  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     getrefreshToken();
  //   }, 5 * 60 * 1000);
  //   return () => clearInterval(intervalId);
  // }, []);


  const contextValue = {
    userProfile,
    roles,
    setRoles,
    getAllRoles,
    setUserProfile,
    languages, description, setDescription,
    serviceTypes,
    getUserProfile,
    setIsLoggedIn,
    isLoggedIn,
    zones,
    states,
    token,
    isLoggedIn,
    setToken,
    successPopup,
    succesMessage,
    setSuccessPopup,
    closePopup,
    setSuccesMessage,
    isConfirmDeleteModelOpen,
    setIsConfirmDeleteModelOpen,
    itemIdToDelete,
    setItemIdToDelete
  };
  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
export { Context, UserContext };
