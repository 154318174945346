import { Box, Button, Container, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../Context/Context";
import apiUrl from "../Config";

const Login = () => {
  const { setIsLoggedIn, setToken,getUserProfile  } = useContext(Context);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors("")
  };
  const navigate=useNavigate()

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        apiUrl+"/users/login",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response && response?.data?.token) {
        // setToken(response.data.token);
        localStorage.setItem("userToken", response.data.token);
        localStorage.setItem("refreshToken", response.data.refreshToken);

        setIsLoggedIn(true);
        setToken(response.data.token);
        getUserProfile()
        navigate('/')
        // window.location.reload();

        
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setErrors(error.response.data);
      } else {
        setErrors("An unexpected error occurred.");
      }
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh", backgroundColor:"white", backgroundSize: "cover", backgroundPosition: "center", }}  >
        <Box sx={{ backgroundColor: "rgba(255, 255, 255, 0.9)", padding: 4, borderRadius: 2, maxWidth: 400, width: "100%", border:"1px solid #EBEBEB"}}  >
          <Typography variant="h4" textAlign="center" mb={3}>  Login  </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ display: "flex", flexDirection: "column", }} >
            <TextField size="small" fullWidth label="Email" name="email" type="email" variant="outlined" value={formData.email} onChange={handleChange}  required
              sx={{ "& .MuiOutlinedInput-root": { "&:hover fieldset": { borderColor: "#EBEBEB", }, }, mb: 2, }} />
            <TextField fullWidth size="small" type="password" label="Password" name="password" variant="outlined" onChange={handleChange}
              required
              value={formData.password}
              sx={{ "& .MuiOutlinedInput-root": { "&:hover fieldset": { borderColor: "#EBEBEB", }, }, mb: 2, }} />

            {errors && (<Typography color="error" textAlign="center" mb={2}>  {errors} </Typography>)}

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button type="submit" variant="contained" color="primary" fullWidth>  Login </Button>
            </Box>

            <Box sx={{ mt: 3, textAlign: "end"  }}>
              <Typography variant="h6" sx={{color:''}}>
                <Link to="/forgotpassword" style={{ textDecoration: "none", color: "red" }} >  Forgot Password? </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Login;

