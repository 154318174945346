import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, MenuItem, Select, FormControl, InputLabel, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Modal, TextField, Grid, Checkbox, FormControlLabel } from '@mui/material';
import axios from 'axios';
import apiUrl from '../../Config';
import { Context } from '../../Context/Context';
import { useLocation, useNavigate } from 'react-router-dom';

const AddPrice = () => {

  const { state } = useLocation()
  const priceDetails = state?.priceDetails

  const [searchId, setSearchId] = useState('');
  const [classificationType, setClassificationType] = useState([]);
  const [classificationName, setClassificationName] = useState('');
  const [selectedClassificationType, setSelectedClassificationType] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [selectedZone, setSelectedZone] = useState('');
  const [subServices, setSubServices] = useState([]);
  const [selectedSubService, setSelectedSubService] = useState('');
  const [miniServices, setMiniServices] = useState([]);
  const [selectedMiniService, setSelectedMiniService] = useState('');
  const [miniServiceError, setMiniServiceError] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    id: 0,
    serviceId: searchId,
    serviceClassificationTypes: selectedClassificationType,
    noOfAdults: 0,
    noOfKids: 0,
    noOfInfants: 0,
    noOfItems: 0,
    noOfVisits: 0,
    minRoomAreainSqft: 0,
    maxRoomAreainSqft: 0,
    baseRate: 0,
    platformFee: 0,
    gst: 0,
    discount: 0,
    membershipDiscountAmount: 0,
    finalPrice: 0,
    professionalPrice: 0,
    isActive: true,
    isDeleted: false
  });

  const { zones, token, serviceTypes } = useContext(Context);
  const navigate = useNavigate();

  const getClassificationTypes = async () => {
    try {
      const response = await axios.get(apiUrl + '/servicerates/getserviceclassifications');
      setClassificationType(response.data.classifications);
    } catch (error) {
      console.error('Error fetching classification types:', error);
    }
  };

  const fetchSubServices = async () => {
    if (!selectedService || !selectedZone) return;
    setLoading(true);
    setError('');
    setSubServices([]);
    try {
      const response = await axios.get(`${apiUrl}/servicetypes/0/getservicesubtypesbyserviceidwithzoneid?serviceId=${selectedService}&zoneId=${selectedZone}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const subservices = response.data.subservices;
      if (subservices?.length > 0) {
        setSubServices(subservices);
      } else {
        setError('No subservices available for the selected service and zone.');
      }
    } catch (err) {
      console.error('Error fetching subservices:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchMiniServices = async () => {
    if (!selectedSubService) return;
    setMiniServices([]);
    setMiniServiceError('');
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/servicetypes/getminiservices?subServiceId=${selectedSubService}`);
      const miniServicesData = response.data;
      if (miniServicesData?.length > 0) {
        setMiniServices(miniServicesData);
      } else {
        setMiniServiceError('Mini services not available.');
      }
    } catch (err) {
      console.error('Error fetching mini services:', err);
      setMiniServiceError('An error occurred while fetching mini services.');
    } finally {
      setLoading(false);
    }
  };

  const createPrice = async (e) => {
    e.preventDefault();
    // setFormData(formData.serviceId = searchId)
    // setFormData(formData.serviceClassificationTypes = selectedClassificationType)
    const updatedFormData = {
      ...formData,
      serviceId: searchId,
      serviceClassificationTypes: selectedClassificationType,
    };
    try {
      const response = await axios.post('https://api.sevaki.in/servicerates/createrate', [updatedFormData], {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status === true) {
        setFormData({
          id: 0,
          serviceId: 0,
          serviceClassificationTypes: '',
          noOfAdults: 0,
          noOfKids: 0,
          noOfInfants: 0,
          noOfItems: 0,
          noOfVisits: 0,
          minRoomAreainSqft: 0,
          maxRoomAreainSqft: 0,
          baseRate: 0,
          platformFee: 0,
          gst: 0,
          discount: 0,
          membershipDiscountAmount: 0,
          finalPrice: 0,
          professionalPrice: 0,
          isActive: true,
          isDeleted: false
        })
      }
      setSelectedClassificationType('')
      setSearchId('')

      console.log('Price Created Successfully', response.data);
    } catch (error) {
      console.error('Error creating price:', error);
    }
  };

  const handleUpdate = async () => {
    console.log(formData, "update func")
    try {
      const response = await axios.post('https://api.sevaki.in/servicerates/updaterate', [formData], {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    } catch (error) {
      console.log(error, "error in update func")
    }
  }

  // const handleChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: type === 'checkbox' ? checked : value
  //   });

  //   // Update `serviceId` and `serviceClassificationTypes` when respective fields are changed
  //   if (name === 'selectedService') {
  //     setSearchId(value);
  //     setFormData({ ...formData, serviceId: value });
  //   }

  //   if (name === 'selectedClassificationType') {
  //     const selectedType = classificationType.find((type) => type.module === value);
  //     let classificationName = '';
  //     if (selectedType) {
  //       if (selectedType.id === 1) {
  //         classificationName = 'Service';
  //       } else if (selectedType.id === 2) {
  //         classificationName = 'SubService';
  //       } else if (selectedType.id === 3) {
  //         classificationName = 'MiniService';
  //       } else {
  //         classificationName = 'MicroService';
  //       }
  //       setClassificationName(classificationName);
  //       setFormData({ ...formData, serviceClassificationTypes: classificationName });
  //     }
  //   }
  // };


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  
    // Update `serviceId` and `serviceClassificationTypes` when respective fields are changed
    if (name === 'selectedService') {
      setSearchId(value);
      setFormData((prevFormData) => ({ ...prevFormData, serviceId: value }));
    }
  
    if (name === 'selectedClassificationType') {
      const selectedType = classificationType.find((type) => type.module === value);
      let classificationName = '';
      if (selectedType) {
        if (selectedType.id === 1) {
          classificationName = 'Service';
        } else if (selectedType.id === 2) {
          classificationName = 'SubService';
        } else if (selectedType.id === 3) {
          classificationName = 'MiniService';
        } else {
          classificationName = 'MicroService';
        }
        setClassificationName(classificationName);
        setFormData((prevFormData) => ({ ...prevFormData, serviceClassificationTypes: classificationName }));
      }
    }
  };

  // useEffect(() => {
  //   getClassificationTypes();
  //   setFormData(priceDetails)
  // }, []);

  useEffect(() => {
    getClassificationTypes();
    setFormData((prevFormData) => ({ ...prevFormData, ...priceDetails }));
  }, []);


  useEffect(() => {
    fetchSubServices();
  }, [selectedService, selectedZone]);

  useEffect(() => {
    fetchMiniServices();
  }, [selectedSubService]);

  useEffect(() => {
    if (selectedMiniService) {
      setSearchId(selectedMiniService);
    } else if (selectedSubService) {
      setSearchId(selectedSubService);
    } else if (selectedService) {
      setSearchId(selectedService);
    }
  }, [selectedService, selectedSubService, selectedMiniService]);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" color="initial" fontWeight={600} >Add Prices</Typography>
        <Button variant="contained" color="error" onClick={() => navigate(-1)} >
          Cancel
        </Button>
      </Box>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Box mt={2} sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>

            <FormControl sx={{ minWidth: 200 }} size="small">
              <InputLabel id="classification-type-label">Classification Type</InputLabel>
              <Select
                labelId="classification-type-label"
                value={selectedClassificationType}
                onChange={(e) => setSelectedClassificationType(e.target.value)}
                label="Classification Type"
              >
                {classificationType.map((type) => (
                  <MenuItem key={type.id} value={type.module}>
                    {type.module}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 200 }} size="small">
              <InputLabel id="service-select-label">Select Service</InputLabel>
              <Select
                labelId="service-select-label"
                value={selectedService}
                onChange={(e) => setSelectedService(e.target.value)}
                label="Select Service"
              >
                {serviceTypes?.map((service) => (
                  <MenuItem key={service.id} value={service.id}>
                    {service.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 200 }} size="small">
              <InputLabel id="zone-select-label">Select Zone</InputLabel>
              <Select
                labelId="zone-select-label"
                value={selectedZone}
                onChange={(e) => setSelectedZone(e.target.value)}
                label="Select Zone"
              >
                {zones?.map((zone) => (
                  <MenuItem key={zone.id} value={zone.id}>
                    {zone.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {subServices.length > 0 && (
              <FormControl sx={{ minWidth: 200 }} size="small">
                <InputLabel id="subservice-select-label">Select Subservice</InputLabel>
                <Select
                  labelId="subservice-select-label"
                  value={selectedSubService}
                  onChange={(e) => setSelectedSubService(e.target.value)}
                  label="Select Subservice"
                >
                  {subServices.map((subservice) => (
                    <MenuItem key={subservice.id} value={subservice.id}>
                      {subservice.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {error && <Typography color="error">{error}</Typography>}

            {miniServices.length > 0 ? (
              <FormControl sx={{ minWidth: 200 }} size="small">
                <InputLabel id="miniservice-select-label">Select Mini Service</InputLabel>
                <Select
                  labelId="miniservice-select-label"
                  value={selectedMiniService}
                  onChange={(e) => setSelectedMiniService(e.target.value)}
                  label="Select Mini Service"
                >
                  {miniServices.map((miniService) => (
                    <MenuItem key={miniService.id} value={miniService.id}>
                      {miniService.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              miniServiceError && <Typography color="error" mt={2}>{miniServiceError}</Typography>
            )}
          </Box>
        </Box>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <CircularProgress />
          </Box>
        )}

        <Box>
          <Box component={'form'} onSubmit={createPrice}>
            <Grid container spacing={2}>

              {/* Row 1 */}
              <Grid item lg={4}>
                <TextField
                  label="ID"
                  type="number"
                  name="id"
                  value={formData?.id}
                  onChange={handleChange}
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  label="No of Adults"
                  type="number"
                  name="noOfAdults"
                  value={formData?.noOfAdults}
                  onChange={handleChange}
                  fullWidth
                  size='small'
                />
              </Grid>

              {/* Row 2 */}
              <Grid item lg={4}>
                <TextField
                  label="No of Kids"
                  type="number"
                  name="noOfKids"
                  value={formData?.noOfKids}
                  onChange={handleChange}
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  label="No of Infants"
                  type="number"
                  name="noOfInfants"
                  value={formData?.noOfInfants}
                  onChange={handleChange}
                  fullWidth
                  size='small'
                />
              </Grid>

              {/* Row 3 */}
              <Grid item lg={4}>
                <TextField
                  label="No of Items"
                  type="number"
                  name="noOfItems"
                  value={formData?.noOfItems}
                  onChange={handleChange}
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  label="No of Visits"
                  type="number"
                  name="noOfVisits"
                  value={formData?.noOfVisits}
                  onChange={handleChange}
                  fullWidth
                  size='small'
                />
              </Grid>

              {/* Row 4 */}
              <Grid item lg={4}>
                <TextField
                  label="Base Rate"
                  type="number"
                  name="baseRate"
                  value={formData?.baseRate}
                  onChange={handleChange}
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  label="Platform Fee"
                  type="number"
                  name="platformFee"
                  value={formData?.platformFee}
                  onChange={handleChange}
                  fullWidth
                  size='small'
                />
              </Grid>

              <Grid item lg={4}>
                <TextField
                  label="Min room area in sqft"
                  type="number"
                  name="minRoomAreainSqft"
                  value={formData?.minRoomAreainSqft}
                  onChange={handleChange}
                  fullWidth
                  size='small'
                />
              </Grid>

              <Grid item lg={4}>
                <TextField
                  label="Max room area in sqft"
                  type="number"
                  name="maxRoomAreainSqft"
                  value={formData?.maxRoomAreainSqft}
                  onChange={handleChange}
                  fullWidth
                  size='small'
                />
              </Grid>

              {/* Row 5 */}
              <Grid item lg={4}>
                <TextField
                  label="GST"
                  type="number"
                  name="gst"
                  value={formData?.gst}
                  onChange={handleChange}
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  label="Discount"
                  type="number"
                  name="discount"
                  value={formData?.discount}
                  onChange={handleChange}
                  fullWidth
                  size='small'
                />
              </Grid>

              {/* Row 4 */}
              <Grid item lg={4}>
                <TextField
                  label="Membership Discount Amount"
                  type="number"
                  name="membershipDiscountAmount"
                  value={formData?.membershipDiscountAmount}
                  onChange={handleChange}
                  fullWidth
                  size='small'
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  label="Final Price"
                  type="number"
                  name="finalPrice"
                  value={formData?.finalPrice}
                  onChange={handleChange}
                  fullWidth
                  size='small'
                />
              </Grid>

              <Grid item lg={4}>
                <TextField
                  label="Professional Price"
                  type="number"
                  name="professionalPrice"
                  value={formData?.professionalPrice}
                  onChange={handleChange}
                  fullWidth
                  size='small'
                />
              </Grid>

              {/* Row 7 */}
              <Grid item lg={4}>
                
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData?.isActive}
                      onChange={handleChange}
                      name="isActive"
                    />
                  }
                  label="Active"
                />
              </Grid>
              <Grid item lg={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData?.isDeleted}
                      onChange={handleChange}
                      name="isDeleted"
                    />
                  }
                  label="Deleted"
                />
              </Grid>

              {/* Submit Button */}

            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button type="submit" variant="contained" color="primary" >
                Submit
              </Button>
              <Button type="button" variant="contained" color="primary" onClick={() => handleUpdate()} >
                update
              </Button>

            </Box>
          </Box>
        </Box>
      </Box>
    </Box >
  );
};

export default AddPrice;
