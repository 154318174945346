import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography,
  Box, Select, MenuItem, FormControl, InputLabel, IconButton, Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import apiUrl from "../../Config";
import { Context } from "../../Context/Context";
import SuccessPopup from '../../Common/SuccessPopup';
import { Pencil, Trash2 } from "lucide-react";
import DeleteConfirm from "../../Common/DeleteConfirm";

// Dummy image URL for no data
const NO_DATA_IMAGE_URL =
  "https://via.placeholder.com/400x300?text=No+Data+Available";

const AllTrainingVideos = () => {
  const { languages, serviceTypes, token, successPopup, succesMessage, setSuccessPopup,
    closePopup, setSuccesMessage, isConfirmDeleteModelOpen, setIsConfirmDeleteModelOpen,
    itemIdToDelete, setItemIdToDelete } = useContext(Context);
  const [videos, setVideos] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  // Fetch video data with selected filters
  const fetchVideos = async () => {
    if (selectedLanguage && selectedService) {
      try {
        const response = await axios.get(
          apiUrl +
          `/trainingvideo/listvideos?languageId=${selectedLanguage}&serviceId=${selectedService}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
        );
        if (response.data && response.data.status) {
          setVideos(response.data.videodetails);
        } else {
          setVideos([]);
        }
      } catch (error) {
        if (error.response && error.response.data.errors) {
          const { serviceId, languageId } = error.response.data.errors;
          setErrorMessage(languageId || serviceId);
        } else {
          setErrorMessage("An unexpected error occurred");
        }
      }
    }
  };

  // Fetch all videos initially or when filters change
  useEffect(() => {
    if (selectedLanguage && selectedService) {
      fetchVideos();
    }

  }, [selectedLanguage, selectedService]);

  // Update video details
  const handleUpdate = (id) => {
    navigate("/addtraingvideo", { state: { id: id } });
  };

  const confirmDeleteTraingVideo = (videoId) => {
    setItemIdToDelete(videoId)
    setIsConfirmDeleteModelOpen(true);
  }

  // Delete video
  const handleDeleteTraingVideo = async () => {
    setIsConfirmDeleteModelOpen(false);
    try {
      const response = await axios.delete(
        `https://api.sevaki.in/trainingvideo/${itemIdToDelete}`
        , {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      if (response.data.status === true) {
        setSuccesMessage(response?.data?.message)
        setSuccessPopup(true)
        fetchVideos();

      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        setSuccesMessage(error?.response?.data?.title)
        setSuccessPopup(true)
      } else {
        setErrorMessage(error?.response?.data?.message || "Something went wrong");
      }
    }
  };

  // Navigate to Add New Video page
  const handleAddNewVideo = () => {
    navigate("/addtraingvideo");
  };

  // Helper function to get language name by id
  const getLanguageName = (languageId) => {
    const language = languages.find((lang) => lang.id === languageId);
    return language ? language.name : "Unknown";
  };

  // Helper function to get service name by id
  const getServiceName = (serviceId) => {
    const service = serviceTypes.find((service) => service.id === serviceId);
    return service ? service.name : "Unknown";
  };


  return (
    <>
      <Box p={3} mt={5}>
        {/* Add New Video Button */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h4" >Video List</Typography>
          <Button variant="contained" color="primary" onClick={handleAddNewVideo}>
            Add New Video
          </Button>
        </Box>
        <Divider />

        {/* Filter Dropdowns */}
        <Box display="flex" gap={2} mb={2} mt={2}>
          {/* Language Dropdown */}
          <FormControl variant="outlined" sx={{ minWidth: 200 }} size="small">
            <InputLabel>Select Language</InputLabel>
            <Select
              value={selectedLanguage}
              onChange={(e) => {
                setSelectedLanguage(e.target.value);
                fetchVideos(e.target.value, selectedService);
              }}
              label="Select Language"
            >
              <MenuItem value="">All</MenuItem>
              {languages?.map((lang) => (
                <MenuItem key={lang.id} value={lang.id}>
                  {lang.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Service Dropdown */}
          <FormControl variant="outlined" sx={{ minWidth: 200 }} size="small">
            <InputLabel>Select Service</InputLabel>
            <Select
              value={selectedService}
              onChange={(e) => {
                setSelectedService(e.target.value);
                fetchVideos(selectedLanguage, e.target.value);
              }}
              label="Select Service"
            >
              <MenuItem value="">All</MenuItem>
              {serviceTypes.map((service) => (
                <MenuItem key={service.id} value={service.id}>
                  {service.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* Conditional Rendering for Video Table or No Data Image */}
        {videos.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell>Video</TableCell>
                <TableCell>ThumbImage</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Language</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Service</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {videos.map((video) => (
                  <TableRow key={video.id}>
                      <TableCell>
                      <video width="130" height="80" controls>
                        <source src={`${apiUrl}/${video?.filePath}`} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </TableCell>
                    <TableCell>
                      <img
                        src={`${apiUrl}/${video?.thumbImageFilePath}`}
                        alt="Thumbnail"
                        style={{ width: "130px", height: "80px", objectFit: "cover" }}
                      />
                    </TableCell>
                    <TableCell>{video?.title}</TableCell>
                    <TableCell>{getLanguageName(video?.languageId)}</TableCell>
                    <TableCell>{video?.description}</TableCell>
                    <TableCell>{getServiceName(video?.serviceId)}</TableCell>
                    {/* <TableCell>{video?.filePath}</TableCell> */}
                  
                    <TableCell>
                      {video?.isActive ? "Active" : "Inactive"}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleUpdate(video?.id)} color="primary">
                        <Pencil size={18} />
                      </IconButton>
                      <IconButton onClick={() => confirmDeleteTraingVideo(video?.id)} color="error" sx={{padding:"0px"}}>
                        <Trash2 size={18} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
            <img
              src={NO_DATA_IMAGE_URL}
              alt="No data available"
              style={{ width: "400px", height: "300px", marginBottom: "16px" }}
            />
            <Typography variant="h6" color="textSecondary">
              No videos available. Please select different filters.
            </Typography>
          </Box>
        )}
      </Box>
      {succesMessage ?
        <SuccessPopup
          open={successPopup}
          onClose={closePopup}
          message={succesMessage}
        /> : ""}
      <DeleteConfirm
        open={isConfirmDeleteModelOpen}
        onClose={() => setIsConfirmDeleteModelOpen(false)}
        onConfirm={handleDeleteTraingVideo}

      />
    </>
  );
};

export default AllTrainingVideos;
