
import React, { useContext, useEffect } from "react";
import {
    Modal,
    Typography,
    Button,
    Box,
} from "@mui/material";
import { Context } from "../Context/Context";
const SuccessPopup = ({ message, open, onClose }) => {
    const { setSuccesMessage } = useContext(Context);
    useEffect(() => {
        if (open) {
            const timer = setTimeout(() => {
                onClose();
                setSuccesMessage("");
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [open, onClose]);

    const handleNavigate = () => {
        onClose();
        setSuccesMessage("");
    };


    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                    width: 400,
                }}
            >
                {message === "Unauthorized" ? (
                    <Typography
                        id="modal-title"
                        variant="h6"
                        component="h2"
                        gutterBottom
                        color="error"
                    >
                        You do not have permission to access this action.
                    </Typography>
                ) : (
                    <div className="success-animation">
                        <svg
                            className="checkmark"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 52 52"
                        >
                            <circle
                                className="checkmark__circle"
                                cx="26"
                                cy="26"
                                r="25"
                                fill="none"
                            />
                            <path
                                className="checkmark__check"
                                fill="none"
                                d="M14.1 27.2l7.1 7.2 16.7-16.8"
                            />
                        </svg>
                    </div>
                )}
                <Typography id="modal-description" variant="body1" mb={2}>
                    {message}
                </Typography>
                <Button
                    onClick={handleNavigate}
                    variant="contained"
                    color={message === "Unauthorized" ? "error" : "primary"}
                    fullWidth
                >
                    Close
                </Button>
            </Box>
        </Modal>
    );
};

export default SuccessPopup;
